import { useEffect } from "react";
import Socket from "../../../socket/socketIOAdmin";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Blink from "../../../utils/blinkTitle";
import LuxonUtils from "@date-io/luxon";
const luxon = new LuxonUtils();

const OrdersNotifications = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    const blink = new Blink("Nouvelle commande");
    Socket().on("notif", data => {
      if (
        luxon.date(data.pickupDate).toISODate() === luxon.date().toISODate()
      ) {
        blink.startBlink();
        dispatch({ type: "new-notif", data });
      }

      if (data.orderType === "orders") {
        toast.warn("Nouveau Take-Out");
      }

      if (data.orderType === "deliveries") {
        toast.warn("Nouvelle livraison");
      }

      if (data.orderType === "pams") {
        toast.warn("Nouveau Prêt-à-manger");
      }
    });
    return () => Socket().off("notif");
  }, [dispatch, pathname]);
  return null;
};

export default OrdersNotifications;
