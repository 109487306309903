import React from "react";
import ListItemText from "@material-ui/core/ListItemText";

const MenuItemDescription = ({
  description,
  subdescription,
  price,
  horsStock
}) => {
  const DollarSign = () => {
    if (window.location.host === "boucheriesalaisonlimoges.pizzli.ca") {
      return "$";
    }
    return "";
  };

  return (
    <ListItemText
      primary={
        <>
          {description} -{" "}
          <i style={{ fontSize: 14 }}>
            {price / 100}
            <DollarSign />
          </i>
          <div style={{ fontSize: 12 }}>{subdescription}</div>
        </>
      }
      style={{
        flex: 1,
        minWidth: 150,
        textDecoration: horsStock ? "line-through" : ""
      }}
      primaryTypographyProps={{
        style: { fontWeight: 300 }
      }}
    />
  );
};

export default MenuItemDescription;
