import socketIOClient from "socket.io-client";
import { toast } from "react-toastify";
import socketbasic from "./socketIO";
import Restaurants from "../utils/Restaurants";

const path = Restaurants().backendEndPoint() + "admin";

let socket = null;

export const initSocket = token => {
  if (socket) {
    return socket;
  }

  socket = socketIOClient(path, {
    transports: ["websocket"],
    query: { token },
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity
  });

  socket.on("connect_error", error => {
    toast.error(
      `Impossible de se connecter au Socket. L'app ne fonctionnera pas correctement`
    );
  });
  socket.on("reconnect", () => window.location.reload());

  socket.on("error", error => {
    toast.error(
      `Impossible de se connecter au Socket. L'app ne fonctionnera pas correctement`
    );
  });
  socket.on("misc-error", error => {
    toast.error(error);
  });

  return socket;
};

export const removeSocket = () => {
  socket = null;
};

export default () => {
  if (!socket) {
    return socketbasic();
  }

  return socket;
};
