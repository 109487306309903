import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Orders from "./Orders";
import LuxonUtils from "@date-io/luxon";
import TabPanel from "./Tabs/TabPanel";

const luxon = new LuxonUtils();

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    marginTop: 100,
    marginBottom: 100,
    backgroundColor: "initial"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: 200
  },
  panel: {
    flex: 1,
    paddingTop: 0,
    marginTop: "-60px",
    minHeight: "100vh"
  },
  box: {
    paddingTop: 0
  }
}));

export default function VerticalTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [date, setDate] = React.useState(luxon.date().startOf("day"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/* <KeyboardDatePicker
        disableToolbar
        className="order-datepicker"
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id="Date"
        label="Date"
        value={date}
        onChange={e => setDate(e)}
        keyboardbuttonprops={{
          "aria-label": "change date"
        }}
      /> */}
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="Nouvelles" {...a11yProps(0)} />
          <Tab label="Archivées" {...a11yProps(1)} />
        </Tabs>
        <TabPanel className={classes.panel} value={value} index={0}>
          <Orders
            notifType="pams"
            orderType="delivery"
            date={date}
            init="initDeliveries"
            emit="initPams"
            archivedEmit="archiveOrder"
            noOrderMsg="Aucun Prêt-à-manger"
            onKey="newPam"
          />
        </TabPanel>
        <TabPanel className={classes.panel} value={value} index={1}>
          <Orders
            notifType="pams"
            orderType="delivery"
            date={date}
            emit="archivedPams"
            init="initTakeOuts"
            archivedEmit="unarchiveOrder"
            noOrderMsg="Aucun Prêt-à-manger archivé"
          />
        </TabPanel>
      </div>
    </>
  );
}
