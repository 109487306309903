import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const SecureRoute = ({ children, path }) => {
  const { user } = useSelector(state => state.auth);

  if (user && user.user.role === "admin") {
    return (
      <Route path={path}>
        <Suspense fallback={<></>}>{children}</Suspense>
      </Route>
    );
  } else {
    return (
      <Route path={path}>
        <Redirect to="/take-outs" />
      </Route>
    );
  }
};

export default SecureRoute;
