import React from "react";
import FormAddressStandard from "./FormAddressStandard";
import { useSelector } from "react-redux";
import FADHandler from "./FADHandler";

const FormAddress = () => {
  const hasDynamicDeliveryFee = useSelector(
    state => state.settings.hasDynamicDeliveryFee.value
  );
  const orderType = useSelector(
    state => state.purchase.orderDetails.type.value
  );

  if (orderType !== "delivery") {
    return null;
  } else if (hasDynamicDeliveryFee) {
    return <FADHandler />;
  } else {
    return <FormAddressStandard />;
  }
};

export default FormAddress;
