import React from "react";
import { DatePicker } from "@material-ui/pickers";

const TabDatePicker = ({ setOpen, ...rest }) => {
  return (
    <DatePicker
      variant="inline"
      PopoverProps={{
        onClick: () => {
          setOpen(false);
        }
      }}
      disableToolbar
      className="order-tabDatepicker"
      format="dd/MM/yyyy"
      margin="normal"
      id="Date"
      onMouseDown={() => setOpen(true)}
      keyboardbuttonprops={{
        "aria-label": "change date"
      }}
      {...rest}
    />
  );
};

export default TabDatePicker;
