import React from "react";
import Grid from "@material-ui/core/Grid";
import ViewHandler from "../ViewHandler";

const MainGridInner = ({ children, view }) => {
  return (
    <ViewHandler view={view}>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          marginTop: 100,
          marginBottom: 40,
          maxWidth: "100%",
          width: "100%"
        }}
      >
        <div className="menu-container">{children}</div>
      </Grid>
    </ViewHandler>
  );
};

export default MainGridInner;
