import React, { useEffect } from "react";
import DateTime from "luxon/src/datetime.js";
import Settings from "luxon/src/settings.js";
import { initSocket } from "../socket/socketIO";
import { AuthCheck } from "../Store/Actions/Auth-Actions";
import { useDispatch, useSelector, batch } from "react-redux";

const SettingsHandler = () => {
  const reduxDispatch = useDispatch();

  const { isInit, restaurantName, tz, currentDate } = useSelector(
    state => state.settings
  );

  useEffect(() => {
    if (currentDate) {
      Settings.defaultZoneName = tz ? tz : "local";
      Settings.now = () => Date.parse(currentDate);
    }
    // eslint-disable-next-line
  }, [tz, currentDate]);

  useEffect(() => {
    const socket = initSocket();
    if (!isInit) {
      socket.emit("get-settings", {}, settings => {
        batch(() => {
          reduxDispatch({ type: "initSettings", settings });
          reduxDispatch({
            type: "[PURCHASE] set details",
            data: { value: settings.tipOptions.default, id: "tip" }
          });
          reduxDispatch({
            type: "[PURCHASE] set details",
            data: {
              value: DateTime.local(),
              valid: true,
              id: "date"
            }
          });
        });
      });
    }

    if (restaurantName) {
      document.title = `Pizzli | ${restaurantName.value}`;
    }

    reduxDispatch(AuthCheck());
    // eslint-disable-next-line
  }, [reduxDispatch, restaurantName]);

  return null;
};

export default SettingsHandler;
