import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { Login as login } from "../../Store/Actions/Auth-Actions";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const empty = !email && !password;
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.auth);
  const { companyPicture } = useSelector(state => state.settings);
  const handleLogin = () => {
    dispatch(login(email, password));
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  return (
    <div className="login-wrapper">
      <Card>
        <CardContent className="login-content">
          <img
            width="250"
            style={{ margin: "50px auto" }}
            src={companyPicture.path}
            alt="company logo"
          />

          <TextField
            value={email}
            onChange={e => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
            id="courrielid"
            label="Courriel"
            variant="outlined"
          />
          <TextField
            value={password}
            onKeyPress={handleKeyPress}
            onChange={e => setPassword(e.target.value)}
            id="passwordid"
            type="password"
            label="Mot de passe"
            variant="outlined"
          />
          <Button
            disabled={isLoading || empty}
            color="primary"
            variant="contained"
            size="small"
            onClick={handleLogin}
          >
            {isLoading ? (
              <CircularProgress style={{ width: 22, height: 22 }} />
            ) : (
              "Connexion"
            )}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;
