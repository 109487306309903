import React, { useCallback } from "react";
import Subheader from "../UI/Subheader";
import { CardElement } from "react-stripe-elements";
import { useSelector, useDispatch } from "react-redux";
import { getTotalWithTaxesAndTip } from "../Utils/cart";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useTheme } from "@material-ui/core/styles";

const FormPayment = () => {
  const theme = useTheme();
  const reduxDispatch = useDispatch();

  const { stripeKey } = useSelector(state => state.settings);
  const error = useSelector(state => state.purchase.card.error);
  const totalWithTaxesAndTip = getTotalWithTaxesAndTip();

  const onChange = useCallback(
    ({ error, complete }) => {
      reduxDispatch({
        type: "[PURCHASE] set card",
        data: { error, complete }
      });
    },
    [reduxDispatch]
  );

  const ErrorMsg = () => {
    if (error === undefined) {
      return null;
    }
    return (
      <FormHelperText className="error-msg">{error.message}</FormHelperText>
    );
  };

  const TestMsg = () => {
    if (!stripeKey.value.includes("test")) {
      return null;
    }

    return (
      <p style={{ textAlign: "center" }}>
        <i>
          À des fins de test, utiliser le numero de carte 4242 4242 4242 4242
        </i>
      </p>
    );
  };

  return (
    <>
      <Subheader
        style={{ marginTop: 20 }}
      >{`Paiement - ${totalWithTaxesAndTip}$`}</Subheader>
      <TestMsg />
      <ErrorMsg />
      <CardElement
        hidePostalCode
        className="card-element"
        style={{
          base: {
            fontFamily: '"Roboto", sans-serif',
            fontSize: "16px",
            fontWeight: "100",
            color: "#6e6e6e",
            "::placeholder": {
              color: "#bcbcbc"
            }
          },
          invalid: {
            color: theme.palette.error.main
          }
        }}
        onChange={onChange}
      />
    </>
  );
};

export default FormPayment;
