import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TabPanel from "./TabPanel";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: "flex",
    marginTop: 100,
    marginBottom: 100,
    backgroundColor: "initial"
  },
  rootPanel: {
    width: "100%"
  }
}));

const TabSelectADateContent = ({ onClick }) => {
  return (
    <Grid container justify="center" direction="column">
      <div className="custom-panel-inner">
        <div className="info">
          Ici, vous pouvez consulter les commandes de n'importe quelle journée.
        </div>
        <Button
          onClick={onClick}
          color="primary"
          variant="contained"
          size="small"
        >
          Choisir une date
        </Button>
      </div>
    </Grid>
  );
};

const TabSelectADate = ({ value, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TabPanel classes={{ root: classes.rootPanel }} value={value} index={0}>
        <TabSelectADateContent onClick={onClick} />
      </TabPanel>
      <TabPanel value={value} classes={{ root: classes.rootPanel }} index={1}>
        <TabSelectADateContent onClick={onClick} />
      </TabPanel>
    </div>
  );
};

export default TabSelectADate;
