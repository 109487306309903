import React from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import socket from "../../../socket/socketIO";
import PrimaryButton from "../../../components/PrimaryButton";
import { toast } from "react-toastify";
import { getOrder } from "../Utils/cart";
import { injectStripe } from "react-stripe-elements";
import Currency from "currency.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getTotal, getTotalWithTaxesAndTip } from "../Utils/cart";

const OrderButton = ({ stripe, children, disabled, ...rest }) => {
  const reduxDispatch = useDispatch();

  const { infos, orderDetails, address, options } = useSelector(
    state => state.purchase
  );
  const takeoutFee = useSelector(state => state.purchase.options.takeoutFee);
  const lock = useSelector(state => state.purchase.lock);

  const order = () => {
    reduxDispatch({
      type: "[PURCHASE] set lock",
      data: { value: true }
    });

    const getOptionsData = () => {
      let opts = { donation: options.donation, discount: options.discount };

      if (orderDetails.type.value === "delivery") {
        opts = {
          address: {
            street: address.street.value,
            city: address.city.value,
            state: address.state.value,
            zip: address.zip.value
          },
          ...options
        };

        delete opts.status;
      } else {
        opts.takeoutFee = takeoutFee;
      }

      if (opts.donation === undefined) {
        delete opts.donation;
      }

      if (!opts.distance) {
        delete opts.distance;
      }

      if (!opts.duration) {
        delete opts.duration;
      }

      if (opts.discount.key === undefined) {
        delete opts.discount;
      } else {
        delete opts.discount.valid;
      }

      return opts;
    };

    stripe.createToken().then(({ token, error }) => {
      const order = getOrder();

      if (token && token.id) {
        let opts = getOptionsData();

        socket().emit(
          "newOrder",
          {
            orderData: {
              name: infos.name.value,
              phone: infos.phone.value,
              note: orderDetails.note.value,
              pickupDate: orderDetails.date.value,
              pickupTime: orderDetails.hour.value,
              order,
              options: opts,
              tip: Currency(orderDetails.tip.value).format(false),
              totalWithoutTip: getTotal().format(false)
            },
            stripe: {
              source: token.id,
              amount: getTotalWithTaxesAndTip()
                .multiply(100)
                .dollars()
            }
          },
          ({ order }) => {
            if (order) {
              batch(() => {
                reduxDispatch({
                  type: "[PURCHASE] set view",
                  data: { view: "confirmation" }
                });

                reduxDispatch({
                  type: "[PURCHASE] set lock",
                  data: { value: false }
                });

                reduxDispatch({
                  type: "[PURCHASE] set confirmedOrderID",
                  data: { confirmedOrderID: order.id }
                });
              });
            } else {
              toast.error("Impossible de traiter votre carte");
            }
          }
        );
      } else {
        reduxDispatch({
          type: "[PURCHASE] set lock",
          data: { value: false }
        });
        toast.error(error.message);
      }
    });
  };

  return (
    <PrimaryButton {...rest} disabled={disabled || lock} onClick={order}>
      {lock ? <CircularProgress style={{ width: 22, height: 22 }} /> : children}
    </PrimaryButton>
  );
};

export default injectStripe(OrderButton);
