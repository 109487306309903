import React, { useRef } from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Summary from "../../../Purchase/Checkout/Summary";
import ReactToPrint from "react-to-print";
import PrintOrder from "../Order/PrintOrder";
import convertTime from "../../../../utils/convertTime";
import DateTime from "luxon/src/datetime.js";

const Order = ({
  order,
  handleArchived,
  categories,
  orderType,
  isArchived
}) => {
  const ref = useRef(null);
  return (
    <Grid key={order.id} item xs={6}>
      <Card className="ordercard" elevation={3}>
        <CardContent>
          {!!order.pickupTime ? (
            <span className="orderno">{`#${order.id} - ${convertTime(
              order.pickupTime
            )}`}</span>
          ) : (
            <span className="orderno">{`#${order.id}`}</span>
          )}
          <div className="client">{order.name}</div>
          <div className="ordertime">{`${DateTime.fromISO(
            order.orderTime
          ).toLocaleString()}`}</div>
          <div className="phone">{order.phone}</div>
          {order.options.address ? (
            <div className="address">
              {`${order.options.address.street}, ${order.options.address.city}, ${order.options.address.state} ${order.options.address.zip}`}
            </div>
          ) : null}
          {order.options.distance && order.options.distance.text ? (
            <div className="distance">
              <i>{`${order.options.distance.text} (${order.options.duration.text})`}</i>
            </div>
          ) : null}
          <div className="note">Note : {order.note}</div>
          <div className="detail-command">
            <ExpansionPanel elevation={0}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                Détail de la commande
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Summary
                  takeoutFee={order.options.takeoutFee}
                  fee={order.options.deliveryFee}
                  discount={{
                    ...order.options.discount,
                    valid: !!order.options.discount
                  }}
                  donation={
                    order.options.donation
                      ? order.options.donation.amount
                      : null
                  }
                  anonyme={
                    order.options.donation
                      ? order.options.donation.anonyme
                      : null
                  }
                  categories={categories}
                  items={order.order}
                  tip={order.tip}
                  orderType={orderType}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            onClick={handleArchived}
            color="primary"
            variant="contained"
            size="small"
          >
            {isArchived ? "Désarchiver" : "Archiver"}
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button
                fullWidth
                onClick={handleArchived}
                variant="contained"
                size="small"
              >
                Imprimer
              </Button>
            )}
            content={() => ref.current}
          />
        </CardActions>
      </Card>
      <div style={{ display: "none" }}>
        <PrintOrder
          innerRef={ref}
          order={order}
          categories={categories}
          orderType={orderType}
        />
      </div>
    </Grid>
  );
};

export default Order;
