import React, { useEffect, useState, useCallback } from "react";
import isClosed from "./Utils/isClosed";
import MainGrid from "./UI/MainGrid";
import MainGridInner from "./UI/MainGridInner";
import Menu from "./Menu/Menu";
import FormSummary from "./Checkout/FormSummary";
import FormInfo from "./Info/FormInfo";
import socket from "../../socket/socketIO";
import { useSelector, useDispatch, batch } from "react-redux";
import FormOrderType from "./Details/FormOrderType";
import FormDate from "./Details/FormDate";
import FormAddress from "./Details/FormAddress/FormAddress";
import FormHour from "./Details/FormHour";
import FormNote from "./Details/FormNote";
import FormTip from "./TipAndDonation/FormTip";
import FormDonation from "./TipAndDonation/FormDonation";
import FormPayment from "./Checkout/FormPayment";
import FormDiscount from "./Checkout/FormDiscount";
import FormConfirmation from "./Confirmation/FormConfirmation";
import FormClosed from "./Closed/FormClosed";
import ActivityDialog from "./UI/ActivityDialog";
import ScrollTop from "./UI/ScrollTop";
let timer = null;

const Purchase = () => {
  const reduxDispatch = useDispatch();
  const menuInit = useSelector(state => state.purchase.categories.length);
  const { hasLimitedQuantityItem } = useSelector(state => state.purchase);
  const [activityModal, setActivityModal] = useState(false);

  const idletimer = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }
    if (hasLimitedQuantityItem) {
      timer = setTimeout(() => {
        setActivityModal(true);
      }, 60000);
    }
  }, [hasLimitedQuantityItem]);

  useEffect(() => {
    idletimer();
  }, [idletimer, hasLimitedQuantityItem]);

  useEffect(() => {
    if (!menuInit) {
      socket().emit("get-menu", {}, data => {
        batch(() => {
          reduxDispatch({
            type: "[PURCHASE] set menu",
            data: { menu: data.menu }
          });
          reduxDispatch({
            type: "[PURCHASE] set categories",
            data: { categories: data.categories }
          });

          if (isClosed()) {
            reduxDispatch({
              type: "[PURCHASE] set view",
              data: { view: "closed" }
            });
          }
        });
      });
    }
    socket().on("quantity-update", ({ items }) => {
      reduxDispatch({
        type: "[PURCHASE] set quantity left",
        data: { items }
      });
    });

    return () => socket().off("quantity-update");
    // eslint-disable-next-line
  }, []);

  if (!menuInit) {
    return null;
  }

  return (
    <>
      <MainGrid onClick={idletimer} onKeyPress={idletimer}>
        <MainGridInner view="closed">
          <FormClosed />
        </MainGridInner>
        <MainGridInner view="menu">
          <Menu />
        </MainGridInner>
        <MainGridInner view="info">
          <FormInfo />
        </MainGridInner>
        <MainGridInner view="orderDetails-1">
          <FormOrderType />
          <FormDate />
        </MainGridInner>
        <MainGridInner view="orderDetails-2">
          <FormAddress />
          <FormHour />
          <FormNote />
        </MainGridInner>
        <MainGridInner view="tipAndDonation">
          <FormTip />
          <FormDonation />
        </MainGridInner>
        <MainGridInner view="checkout">
          <FormSummary />
          <FormDiscount />
          <FormPayment />
        </MainGridInner>
        <MainGridInner view="confirmation">
          <FormConfirmation />
        </MainGridInner>
        <ActivityDialog
          open={activityModal}
          handleClose={() => setActivityModal(false)}
        />
        <ScrollTop />
      </MainGrid>
    </>
  );
};

export default Purchase;
