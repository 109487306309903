// //if you have another AudioContext class use that one, as some browsers have a limit
// const audioCtx = new (window.AudioContext ||
//   window.webkitAudioContext ||
//   window.audioContext)();

// //duration of the tone in milliseconds. Default is 500
// //frequency of the tone in hertz. default is 440
// //volume of the tone. Default is 1, off is 0.
// //type of tone. Possible values are sine, square, sawtooth, triangle, and custom. Default is sine.
// //callback to use on end of tone
// function beep(duration, frequency, volume, type, callback) {
//   const oscillator = audioCtx.createOscillator();
//   const gainNode = audioCtx.createGain();

//   oscillator.connect(gainNode);
//   gainNode.connect(audioCtx.destination);

//   if (volume) {
//     gainNode.gain.value = volume;
//   }
//   if (frequency) {
//     oscillator.frequency.value = frequency;
//   }
//   if (type) {
//     oscillator.type = type;
//   }
//   if (callback) {
//     oscillator.onended = callback;
//   }

//   oscillator.start(audioCtx.currentTime);
//   oscillator.stop(audioCtx.currentTime + (duration || 500) / 1000);
// }

function Blink(m1, m2) {
  const originalTitle = document.title;
  let msg1 = m1;
  let msg2 = m2 ? m2 : originalTitle;
  let blinkInterval = null;

  function blink() {
    if (document.title === msg1) {
      document.title = msg2;
    } else {
      document.title = msg1;
    }
  }

  this.stopBlink = function() {
    if (blinkInterval) {
      clearInterval(blinkInterval);
    }
    document.body.removeEventListener("click", this.stopBlink, true);
    document.title = originalTitle;
  };

  this.startBlink = function(m1, m2) {
    if (!msg1 || !msg2) {
      msg1 = m1;
      msg2 = m2;
    }
    document.body.addEventListener("click", this.stopBlink, true);
    blinkInterval = setInterval(blink, 500);
    // beep(500, 2000);
    return this;
  };
}

Object.freeze(Blink);
module.exports = Blink;
