import React from "react";
import Subheader from "../UI/Subheader";
import { getOrder } from "../Utils/cart";
import Summary from "./Summary";
import { useSelector } from "react-redux";

const FormSummary = () => {
  const items = getOrder();
  const categories = useSelector(state => state.purchase.categories);
  const tip = useSelector(state => state.purchase.orderDetails.tip.value);
  const donation = useSelector(state => state.purchase.options.donation);
  const deliveryFee = useSelector(state => state.purchase.options.deliveryFee);
  const takeoutFee = useSelector(state => state.purchase.options.takeoutFee);
  const discount = useSelector(state => state.purchase.options.discount);
  const orderType = useSelector(
    state => state.purchase.orderDetails.type.value
  );
  const d = donation !== undefined ? donation.amount : 0;
  const a = donation !== undefined ? donation.anonyme : false;

  return (
    <>
      <Subheader>Sommaire</Subheader>
      <Summary
        categories={categories}
        items={items}
        tip={tip}
        donation={d}
        anonyme={a}
        orderType={orderType}
        fee={deliveryFee}
        takeoutFee={takeoutFee}
        discount={discount}
      />
    </>
  );
};

export default FormSummary;
