import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Orders from "./Orders";
import TabOrderWrapper from "./Tabs/TabOrderWrapper";
import LuxonUtils from "@date-io/luxon";
import TabPanel from "./Tabs/TabPanel";

const luxon = new LuxonUtils();

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fbfbfb"
  },
  rootPanel: {}
}));

export default function AllDeliveries() {
  const classes = useStyles();
  const [date, setDate] = React.useState(luxon.date().startOf("day"));
  const [value, setValue] = React.useState(0);

  return (
    <TabOrderWrapper
      value={value}
      date={date}
      setDate={setDate}
      setValue={setValue}
      init="initDeliveryDates"
      onKey="newDelivery"
    >
      <div className={classes.root}>
        <TabPanel className={classes.panel} value={value} index={0}>
          <Orders
            notifType="deliveries"
            orderType="delivery"
            date={date}
            init="initDeliveries"
            emit="get-deliveries"
            archivedEmit="archiveOrder"
            noOrderMsg="Aucune livraison"
            onKey="newDelivery"
          />
        </TabPanel>
        <TabPanel className={classes.panel} value={value} index={1}>
          <Orders
            notifType="deliveries"
            orderType="delivery"
            date={date}
            emit="get-archivedDeliveries"
            init="initDeliveries"
            archivedEmit="unarchiveOrder"
            noOrderMsg="Aucune livraison archivée"
          />
        </TabPanel>
      </div>
    </TabOrderWrapper>
  );
}
