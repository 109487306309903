import React from "react";
import { useSelector } from "react-redux";

const ViewHandler = ({ view, children }) => {
  const currentView = useSelector(state => state.purchase.view);

  if (view === currentView || view === undefined) {
    return <>{children}</>;
  }
  return <></>;
};

export default ViewHandler;
