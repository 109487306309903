const initialState = {
  isInit: false
};

const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "initSettings":
      return { isInit: true, ...action.settings };
    case "update-settings":
      return updateObject(state, {
        [action.key]: { ...state[action.key], ...action.value }
      });
    default:
      return state;
  }
};

export default SettingsReducer;
