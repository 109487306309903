import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Currency from "currency.js";
import { getSubtotal, getSubtotalTaxable } from "../Utils/cart";

const Summary = ({
  items,
  tip = 0,
  categories,
  fee: deliveryFee,
  takeoutFee,
  donation = 0,
  anonyme = "false",
  orderType = "takeout",
  discount
}) => {
  if (!items) {
    return null;
  }
  const tps = 5;
  const tvq = 9.975;

  const isDelivery = orderType === "delivery";
  const fee = isDelivery ? deliveryFee : takeoutFee;

  const invoiceSubtotalTaxable = Currency(
    getSubtotalTaxable(items, donation, fee || 0)
  );

  let tpsTotal = Currency(0);
  let tvqTotal = Currency(0);

  const disc = discount.value || 0;
  if (invoiceSubtotalTaxable - disc > 0) {
    tpsTotal = Currency(invoiceSubtotalTaxable).multiply(tps / 100);
    tvqTotal = Currency(invoiceSubtotalTaxable).multiply(tvq / 100);
  }

  const invoiceSubtotal = getSubtotal(items, discount, donation, fee || 0);

  const totalWithTaxes = Currency(invoiceSubtotal)
    .add(tpsTotal)
    .add(tvqTotal);

  const tipAmount = Currency(totalWithTaxes).multiply(tip);
  const categoriesIDs = {};

  if (categories) {
    categories.forEach(c => (categoriesIDs[c.id] = c.title));
  }

  const getDiscountDollar = () => {
    if (discount.type === "%") {
      const sub = getSubtotal(items, {}, donation, fee || 0);
      const discountAmount = sub.multiply(discount.value / 10000);
      return `-${discountAmount.format(false)}`;
    } else {
      return `-${(discount.value / 100).toFixed(2)}`;
    }
  };

  const getDiscountPourc = () => {
    if (discount.type === "%") {
      return `-${discount.value / 100}${discount.type}`;
    } else {
      return "";
    }
  };

  return (
    <div className="summary-wrapper">
      <Table aria-label="spanning table">
        <TableHead style={{ fontWeight: "600" }}>
          <TableRow>
            <TableCell align="left">Qté</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Catégorie</TableCell>
            <TableCell align="right">Prix</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(row => (
            <TableRow key={row.id}>
              <TableCell align="left">{row.quantity}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>
                {isNaN(row.category)
                  ? row.category
                  : categoriesIDs[row.category]}
              </TableCell>
              <TableCell align="right">
                {Currency(row.price / 100)
                  .multiply(row.quantity)
                  .format(false)}
              </TableCell>
            </TableRow>
          ))}
          {donation && donation !== 0 ? (
            <TableRow>
              <TableCell />
              <TableCell colSpan={2}>{`Don ${
                String(anonyme) !== "false" ? " - anonyme" : ""
              }`}</TableCell>
              <TableCell align="right">
                {Currency(donation).format(false)}
              </TableCell>
            </TableRow>
          ) : null}

          {takeoutFee && takeoutFee !== 0 ? (
            <TableRow>
              <TableCell />
              <TableCell colSpan={2}>Frais de commande</TableCell>
              <TableCell align="right">
                {Currency(takeoutFee).format(false)}
              </TableCell>
            </TableRow>
          ) : null}

          {isDelivery && (
            <TableRow>
              <TableCell rowSpan={6} />
              <TableCell colSpan={2}>Frais livraison</TableCell>
              <TableCell align="right">
                {Currency(deliveryFee).format(false)}
              </TableCell>
            </TableRow>
          )}

          {discount && discount.valid ? (
            <TableRow>
              <TableCell />
              <TableCell>Code promotionnel</TableCell>
              <TableCell align="right">{getDiscountPourc()}</TableCell>
              <TableCell align="right">{getDiscountDollar()}</TableCell>
            </TableRow>
          ) : null}

          <TableRow>
            {!isDelivery && <TableCell rowSpan={5} />}
            <TableCell colSpan={2}>Sous-total (avant taxes)</TableCell>
            <TableCell align="right">{invoiceSubtotal.format(false)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>TPS</TableCell>
            <TableCell align="right">{`${tps} %`}</TableCell>
            <TableCell align="right">{tpsTotal.format(false)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>TVQ</TableCell>
            <TableCell align="right">{`${tvq} %`}</TableCell>
            <TableCell align="right">{tvqTotal.format(false)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>Sous-total (taxes incluses)</TableCell>
            <TableCell align="right">
              {Currency(invoiceSubtotal)
                .add(tpsTotal)
                .add(tvqTotal)
                .format(false)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Pourboire</TableCell>
            <TableCell align="right">{`${Currency(tip).cents()} %`}</TableCell>
            <TableCell align="right" className="tip">
              {tipAmount.format(false)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>Total</TableCell>
            <TableCell align="right">
              {Currency(totalWithTaxes)
                .add(tipAmount)
                .format(false)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default Summary;
