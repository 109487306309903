import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import getDeliveryFee from "../../../../utils/getDeliveryFee";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Socket from "../../../../socket/socketIO";
import { getSubtotal } from "../../Utils/cart";

const FormAddressDynamic = () => {
  const theme = useTheme();
  const reduxDispatch = useDispatch();

  const street = useSelector(state => state.purchase.address.street);
  const city = useSelector(state => state.purchase.address.city);
  const state = useSelector(state => state.purchase.address.state);
  const zip = useSelector(state => state.purchase.address.zip);
  const addressValid = useSelector(state => state.purchase.address.valid);
  const lock = useSelector(state => state.purchase.lock);

  const setLock = value => {
    reduxDispatch({
      type: "[PURCHASE] set lock",
      data: { value }
    });
  };

  const onChange = ({ target }) => {
    reduxDispatch({
      type: "[PURCHASE] set address",
      data: { value: target.value, id: target.id }
    });
  };

  const handleCity = ({ target: { value } }) =>
    onChange({ target: { value, id: "city" } });

  const handleClose = () => {
    reduxDispatch({
      type: "[PURCHASE] set view",
      data: { view: "orderDetails-1" }
    });
  };

  const handleSubmit = () => {
    setLock(true);

    if (addressValid) {
      Socket().emit(
        "validate-address",
        {
          address: {
            street,
            city,
            state,
            zip
          }
        },
        ({ success, message, ...data }) => {
          const deliveryFee = getDeliveryFee(
            getSubtotal().dollars(),
            data.distance.value
          );

          const status = Number.isNaN(Number.parseFloat(deliveryFee))
            ? deliveryFee
            : "OK";

          if (success) {
            reduxDispatch({
              type: "[PURCHASE] set options",
              data: {
                ...data,
                deliveryFee: status === "OK" ? deliveryFee : undefined,
                status
              }
            });
          } else {
            toast.error(message);
          }
          setLock(false);
        }
      );
    } else {
      setLock(false);
    }
  };

  return (
    <Dialog open aria-labelledby="form-dialog-title" disableBackdropClick>
      <DialogTitle id="form-dialog-title">
        Évaluation de la distance
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Indiquez votre adresse de livraison
        </DialogContentText>
        <TextField
          error={street.valid !== undefined && !street.valid}
          id="street"
          style={{ marginTop: 20 }}
          label="Rue"
          placeholder="Rue"
          value={street.value}
          variant="outlined"
          fullWidth
          onChange={onChange}
          disabled={lock}
        />
        <TextField
          error={city.valid !== undefined && !city.valid}
          id="city"
          style={{ marginTop: 20 }}
          label="Ville"
          placeholder="Ville"
          value={city.value}
          variant="outlined"
          fullWidth
          onChange={handleCity}
          disabled={lock}
        />
        <div className="side-inputs">
          <TextField
            id="state"
            style={{ marginTop: 20 }}
            label="Province"
            placeholder="Province"
            value={state.value}
            variant="outlined"
            disabled
          />
          <TextField
            error={zip.valid !== undefined && !zip.valid}
            id="zip"
            style={{ marginTop: 20 }}
            label="Code postal"
            placeholder="Code postal"
            value={zip.value}
            variant="outlined"
            onChange={onChange}
            disabled={lock}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={lock} color="primary">
          Annuler
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={handleSubmit}
          disabled={lock}
        >
          {lock ? (
            <CircularProgress style={{ width: 22, height: 22 }} />
          ) : (
            "Confirmer"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormAddressDynamic;
