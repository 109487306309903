import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Summary from "../../../Purchase/Checkout/Summary";
import convertTime from "../../../../utils/convertTime";
import DateTime from "luxon/src/datetime.js";
import { days } from "../../../../utils/orderDatesUtils";

const PrintOrder = ({ order, categories, orderType, innerRef }) => {
  const pickupDate = DateTime.fromISO(order.pickupDate);
  return (
    <Grid ref={innerRef} key={order.id} item xs={12}>
      <Card className="ordercard print">
        <CardContent>
          <p>
            <span className="orderno">
              {`#${order.id} - ${convertTime(order.pickupTime)}`}
              <br />
              {`${days[pickupDate.weekday]} ${pickupDate.toLocaleString()}`}
            </span>
          </p>
          <div className="client">{order.name}</div>
          <div className="ordertime">
            <p>{`${DateTime.fromISO(order.orderTime).toLocaleString()}`}</p>
          </div>
          <div className="phone">{order.phone}</div>
          {order.options.address ? (
            <>
              <div className="address">
                {`${order.options.address.street}, ${order.options.address.city}, ${order.options.address.state} ${order.options.address.zip}`}
              </div>
            </>
          ) : null}
          {order.options.distance && order.options.duration.text ? (
            <div className="distance">
              <i>{`${order.options.distance.text} (${order.options.duration.text})`}</i>
            </div>
          ) : null}
          <div className="note">Note : {order.note}</div>
          <div className="detail-command">
            Détail de la commande
            <Summary
              takeoutFee={order.options.takeoutFee}
              fee={order.options.deliveryFee}
              discount={{
                ...order.options.discount,
                valid: !!order.options.discount
              }}
              donation={
                order.options.donation ? order.options.donation.amount : null
              }
              anonyme={
                order.options.donation ? order.options.donation.anonyme : null
              }
              categories={categories}
              items={order.order}
              tip={order.tip}
              orderType={orderType}
            />
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default PrintOrder;
