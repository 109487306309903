const initialState = {
  counts: {
    deliveries: 0,
    orders: 0,
    pams: 0
  }
};

const NotifReducer = (state = initialState, action) => {
  switch (action.type) {
    case "new-notif":
      return {
        ...state,
        counts: {
          ...state.counts,
          [action.data.orderType]: state.counts[action.data.orderType] + 1
        }
      };
    case "reset-specific-notif":
      return {
        ...state,
        counts: {
          ...state.counts,
          [action.data.orderType]: 0
        }
      };
    case "reset-notif":
      return {
        ...state,
        counts: {
          ...state.counts,
          [action.data.orderType]: 0
        }
      };
    default:
      return state;
  }
};

export default NotifReducer;
