import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Subheader from "../UI/Subheader";
import MaskedInput from "../../../components/MaskedInput";

const FormInfo = () => {
  const reduxDispatch = useDispatch();
  const name = useSelector(state => state.purchase.infos.name);
  const phone = useSelector(state => state.purchase.infos.phone);

  const updateInfo = useCallback(
    ({ target: { id, value } }) => {
      reduxDispatch({
        type: "[PURCHASE] set infos",
        data: { value, id }
      });
    },
    [reduxDispatch]
  );

  return (
    <div className="form-info-wrapper">
      <Subheader>Informations</Subheader>
      <MaskedInput
        valid={name.valid === undefined || name.valid}
        label="Nom"
        placeholder="Votre nom"
        stateKey="name"
        errorText="Entrez vortre nom complet"
        value={name.value}
        setValue={updateInfo}
      />
      <MaskedInput
        valid={phone.valid === undefined || phone.valid}
        label="Téléphone"
        name="telephone-order"
        autoComplete="off"
        placeholder="(514) 123-1234"
        stateKey="phone"
        errorText="Numéro invalide"
        value={phone.value}
        setValue={updateInfo}
      />
    </div>
  );
};

export default FormInfo;
