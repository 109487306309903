import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import TuneIcon from "@material-ui/icons/Tune";
import FormatListNumberedRtlIcon from "@material-ui/icons/FormatListNumberedRtl";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AdminOnly from "./AdminOnly";
import { Signout } from "../Store/Actions/Auth-Actions";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  popper: {
    zIndex: 5000
  }
}));

export default function MenuListComposition() {
  const reduxDispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { isInPamMode } = useSelector(state => state.settings);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event, url) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if (url) {
      history.push(url);
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  let reportURL = "/week-report";
  if (isInPamMode.value) {
    reportURL = "/pam-report";
  }

  return (
    <div className={classes.root}>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <SettingsIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <AdminOnly>
                    <MenuItem onClick={e => handleClose(e, "/settings")}>
                      <ListItemIcon>
                        <TuneIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Paramètres" />
                    </MenuItem>
                    <MenuItem onClick={e => handleClose(e, "/users")}>
                      <ListItemIcon>
                        <PeopleIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Utilisateurs" />
                    </MenuItem>
                    <MenuItem onClick={e => handleClose(e, "/menu")}>
                      <ListItemIcon>
                        <RestaurantMenuIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Menu" />
                    </MenuItem>
                    <MenuItem onClick={e => handleClose(e, "/discounts")}>
                      <ListItemIcon>
                        <AttachMoneyIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Promotions" />
                    </MenuItem>
                    <MenuItem onClick={e => handleClose(e, reportURL)}>
                      <ListItemIcon>
                        <FormatListNumberedRtlIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Rapport" />
                    </MenuItem>
                  </AdminOnly>
                  <MenuItem onClick={() => reduxDispatch(Signout())}>
                    <ListItemIcon>
                      <PowerSettingsNewIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Déconnexion" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
