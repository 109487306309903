import React from "react";
import Subheader from "../UI/Subheader";
import { ListSubheader } from "@material-ui/core";
import MenuItem from "./MenuItem";
import { useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";

const Category = ({ title, isAlcool, description }) => {
  const menu = useSelector(state => state.purchase.menu[title]);
  const forceFoodWithAlcool = useSelector(
    state => state.settings.forceFoodWithAlcool.value
  );
  const { totalCountWithNoAlcool } = useSelector(state => state.purchase);
  if (!menu || !menu.length) {
    return null;
  }
  const shouldDisabledItem =
    forceFoodWithAlcool && isAlcool && totalCountWithNoAlcool === 0;
  return (
    <>
      <ListSubheader
        style={{ backgroundColor: "white", color: "#6e6e6e", padding: 0 }}
        disableSticky={true}
      >
        <Subheader>
          {title}{" "}
          <div style={{ fontWeight: 100, fontSize: 12 }}>{description}</div>
        </Subheader>
      </ListSubheader>
      {!!shouldDisabledItem && isAlcool && (
        <Alert severity="info">
          Il doit y avoir au moins 1 produit non alcoolisé
        </Alert>
      )}
      {menu
        .sort((a, b) => a.index - b.index)
        .map(item => {
          return <MenuItem key={item.id} item={item} category={title} />;
        })}
    </>
  );
};

export default Category;
