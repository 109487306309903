import React from "react";
import TextField from "@material-ui/core/TextField";

export default ({
  value,
  setValue,
  stateKey,
  label,
  placeholder,
  valid,
  errorText,
  multiline,
  autoComplete,
  name
}) => {
  const formatEvent = newValue => ({
    target: {
      id: stateKey,
      value: newValue
    }
  });

  const formatPhoneNumber = number => {
    const cleanedNumber = ` ${number}`.replace(/\D/g, "");
    const match = cleanedNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return "";
  };

  const onChangePhone = event => {
    const { selectionStart } = event.target;
    let { value: newValue } = event.target;

    if (
      !RegExp(/\d/).test(newValue[selectionStart - 1]) &&
      newValue.length > value.length
    )
      return;

    const wasAutoFilled = RegExp(/^\d{10}$/).test(newValue);
    if (wasAutoFilled) {
      newValue = formatPhoneNumber(newValue);
    }

    if (!newValue.length) {
      setValue(formatEvent(""));
      return;
    }
    if (newValue[0] !== "(") {
      if (newValue.length === 1) {
        setValue(formatEvent(`(${newValue}`));
      }
      return;
    }
    if (newValue.length > 14 && selectionStart > 14) return;

    if (newValue.length === value.length - 1) {
      if (selectionStart === 4 || selectionStart === 5) {
        newValue = value.slice(0, 3);
      } else if (selectionStart === 9) {
        newValue = value.slice(0, 8);
      } else if (selectionStart === 1) {
        newValue = "";
      } else {
        newValue = value.slice(0, selectionStart);
      }
    } else if (value.length > 4) {
      if (selectionStart < 5) {
        newValue = newValue.slice(0, selectionStart);
      } else if (selectionStart === 5) {
        newValue = `${newValue.slice(0, selectionStart - 1)}) ${
          newValue[selectionStart - 1]
        }`;
      } else if (value.length > 9) {
        if (selectionStart < 10) {
          newValue = newValue.slice(0, selectionStart);
        } else if (selectionStart === 10) {
          newValue = `${newValue.slice(0, selectionStart - 1)}-${
            newValue[selectionStart - 1]
          }`;
        } else if (newValue.length > 14) {
          newValue = newValue.slice(0, selectionStart);
        }
      }
    }

    if (newValue.length === 4) {
      newValue += ") ";
    } else if (newValue.length === 9) {
      newValue += "-";
    }

    setValue(formatEvent(newValue));
  };

  const onChangeName = event => {
    const { value: newValue, selectionStart } = event.target;

    if (newValue.length) {
      const isValidName = RegExp(
        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
      ).test(newValue);

      const twoSpaces =
        newValue[selectionStart - 1] === " " &&
        (newValue[selectionStart - 2] === " " ||
          newValue[selectionStart] === " ");

      const twoDashes =
        newValue[selectionStart - 1] === "-" &&
        (newValue[selectionStart - 2] === "-" ||
          newValue[selectionStart] === "-");

      if (!isValidName || twoSpaces || twoDashes) return;
    }

    setValue(formatEvent(newValue));
  };

  const onBlur = () => {
    if (stateKey === "phone" && value === "(") {
      setValue(formatEvent(""));
    }
  };

  const onChange = e => {
    const { id } = e.target;
    id === "phone" ? onChangePhone(e) : onChangeName(e);
  };

  return (
    <TextField
      style={{ marginTop: 20 }}
      fullWidth
      inputProps={{
        autoComplete: autoComplete,
        name: name
      }}
      error={!valid}
      label={label}
      helperText={!valid ? errorText : ""}
      variant="outlined"
      id={stateKey}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      type={stateKey === "phone" ? "phone" : "text"}
      value={value}
      multiline={multiline}
      rows={multiline ? 4 : null}
    />
  );
};
