import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import SettingsDropdown from "./SettingsDropdown";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminOnly from "./AdminOnly";

const MenuBar = ({ Signout }) => {
  const counts = useSelector(state => state.notif.counts);
  const { isInPamMode } = useSelector(state => state.settings);
  const history = useHistory();

  return (
    <AppBar position="static">
      <div className="admin-container">
        <Toolbar className="toolbar">
          <AdminOnly>
            <Button onClick={() => history.push("/dashboard")} color="inherit">
              Tableau de bord
            </Button>
          </AdminOnly>
          {!isInPamMode.value ? (
            <>
              <Button
                onClick={() => history.push("/take-outs")}
                color="inherit"
              >
                <Badge badgeContent={counts.orders} color="secondary">
                  Emportées
                </Badge>
              </Button>
              <Button
                onClick={() => history.push("/deliveries")}
                color="inherit"
              >
                <Badge badgeContent={counts.deliveries} color="secondary">
                  Livraisons
                </Badge>
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => history.push("/pams")} color="inherit">
                <Badge badgeContent={counts.pams} color="secondary">
                  Prêt-à-manger
                </Badge>
              </Button>
            </>
          )}
          <div className="logout-button">
            <SettingsDropdown Signout={Signout} />
          </div>
        </Toolbar>
      </div>
    </AppBar>
  );
};

export default MenuBar;
