import store from "../../../Store/RootReducer";
import Currency from "currency.js";
import getDeliveryFee from "../../../utils/getDeliveryFee";
import getTakeoutFee from "../../../utils/getTakeoutFee";

export const getOrder = () => {
  const {
    purchase: { menu }
  } = store.getState();

  return [].concat
    .apply([], Object.values(menu))
    .filter(item => item.quantity > 0);
};

export const getTotalCount = () => {
  const order = getOrder();
  return order.reduce((sum, { quantity }) => (sum = sum + quantity), 0);
};

export const getTotalWithTaxesAndTip = () => {
  const {
    purchase: {
      orderDetails: {
        tip: { value: tip }
      }
    }
  } = store.getState();

  const totalWithTaxes = getTotal();
  const tipAmount = totalWithTaxes.multiply(tip);

  return totalWithTaxes.add(tipAmount);
};

export const getTotal = () => {
  const subTotal = getSubtotal();

  const subTotalTaxable = getSubtotalTaxable();

  const tps = 5;
  const tvq = 9.975;

  const tpsTotal = Currency(subTotalTaxable).multiply(tps / 100);
  const tvqTotal = Currency(subTotalTaxable).multiply(tvq / 100);

  const totalWithTaxes = subTotal.add(tpsTotal).add(tvqTotal);

  return totalWithTaxes;
};

export const getSubtotalTaxable = (order, donation, fee) => {
  const {
    purchase: {
      options: {
        distance: { value: distance }
      },
      orderDetails: {
        type: { value: orderType }
      }
    }
  } = store.getState();
  if (!order) {
    order = getOrder();
  }
  let donationValue = 0;
  if (donation !== undefined) {
    donationValue = donation;
  }

  const subTotal = order
    .map(({ price, quantity, taxable }) => {
      if (taxable || taxable === undefined) {
        return Currency(price / 100).multiply(quantity);
      } else {
        return Currency(0);
      }
    })
    .reduce((sum, i) => sum.add(i), Currency(0));

  let f = 0;
  if (fee !== undefined) {
    f = fee;
  } else {
    if (orderType === "delivery") {
      f = getDeliveryFee(subTotal, distance);
    } else if (orderType === "takeout") {
      f = getTakeoutFee(subTotal);
    }
  }

  return subTotal.add(f).add(donationValue);
};

export const getSubtotalNonTaxable = order => {
  let subTotal = order
    .map(({ price, quantity, taxable }) => {
      if (taxable === false) {
        return Currency(price / 100).multiply(quantity);
      } else {
        return Currency(0);
      }
    })
    .reduce((sum, i) => sum.add(i), Currency(0));

  return subTotal;
};

export const getSubtotal = (order, disc, don, fee) => {
  const {
    purchase: {
      options: { donation, discount }
    }
  } = store.getState();

  let items = order;
  if (!items) {
    items = getOrder();
  }

  let dn = {};
  if (don) {
    dn = don;
  } else if (donation) {
    dn = donation;
  }

  const subTotalTaxable = getSubtotalTaxable(items, dn, fee);
  const subTotalNonTaxable = getSubtotalNonTaxable(items);
  const subTotal = subTotalTaxable.add(subTotalNonTaxable);

  let discountValue = Currency(0);

  let d = {};
  if (disc) {
    d = disc;
  } else if (discount) {
    d = discount;
  }

  if (d) {
    if (d.type === "$" && subTotal.intValue > 0) {
      discountValue = Currency(d.value / 100);
    } else if (d.type === "%") {
      discountValue = subTotal.multiply(d.value / 10000);
    }
    discountValue =
      discountValue.intValue >= subTotal.intValue ? subTotal : discountValue;
  }

  return subTotal.subtract(discountValue);
};
