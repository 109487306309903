import jwtDecode from "jwt-decode";
import { getCookie, eraseCookie } from "../../utils/cookieFunctions";
import socketIO from "../../socket/socketIO";
import { removeSocket, initSocket } from "../../socket/socketIOAdmin";

export const AuthCheck = () => {
  return dispatch => {
    const cookie = getCookie("accessToken");
    dispatch(AuthStart());
    if (!cookie) dispatch(Signout());
    else {
      const user = jwtDecode(cookie);
      initSocket(cookie);
      dispatch(AuthSuccess(user));
    }
  };
};

export const Login = (email, password) => {
  return dispatch => {
    dispatch(AuthStart());
    socketIO().emit("login", { email, password }, resp => {
      if (resp.success) {
        document.cookie = `accessToken=${resp.token}`;
        const user = jwtDecode(resp.token);
        initSocket(resp.token);
        dispatch(AuthSuccess(user));
      } else {
        dispatch(Signout());
      }
    });
  };
};

export const AuthStart = () => {
  return {
    type: "AuthStart"
  };
};
export const AuthSuccess = user => {
  return {
    type: "AuthSuccess",
    user
  };
};
export const Signout = () => {
  eraseCookie("accessToken");
  removeSocket();
  return {
    type: "Signout"
  };
};
