const initialState = {
  isAuthenticated: false,
  isLoading: false,
  user: null
};

const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};
const AuthSuccess = (state, action) => {
  return updateObject(state, {
    isLoading: false,
    isAuthenticated: true,
    user: action.user
  });
};
const AuthStart = (state, action) => {
  return updateObject(state, { isLoading: true });
};
const Signout = (state, action) => {
  return updateObject(state, {
    isAuthenticated: false,
    user: null,
    isLoading: false
  });
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "AuthSuccess":
      return AuthSuccess(state, action);
    case "AuthStart":
      return AuthStart(state, action);
    case "Signout":
      return Signout(state, action);
    default:
      return state;
  }
};

export default AuthReducer;
