import React from "react";
import { useSelector } from "react-redux";
import { days } from "../../../utils/orderDatesUtils";
import convertTime from "../../../utils/convertTime";
import DateTime from "luxon/src/datetime.js";

const FormClosed = () => {
  const closedMsg = useSelector(state => state.settings.closedMsg.value);
  const openingHour = useSelector(state => state.settings.openingHour);
  const hours = Object.values(openingHour);

  const formatTime = time => {
    if (time === 0) {
      return "Minuit";
    }
    return convertTime(time);
  };

  const today = DateTime.local();
  const weekDay = today.weekday === 7 ? 0 : today.weekday;

  // todo temp link
  if (window.location.host === "qg.pizzli.ca") {
    return (
      <div className="closed">
        <div className="closed-msg">
          <p>
            Désolé, nous avons tout vendu. Allez voir l’offre de notre petit
            frère l’<a href="https://em.pizzli.ca/">État Major</a>.
          </p>
          <p>Merci de vos encouragements</p>
        </div>
        <div className="time-container">
          <h3>Heure d'ouverture des commandes</h3>
          {hours.map((h, i) => {
            if (h.isClosed) {
              return (
                <div className={`time-row${i === weekDay ? " bold" : ""}`}>
                  <div className="day">{days[i]}</div>
                  <div className="from">Fermé</div>
                  <div className="to">Fermé</div>
                </div>
              );
            }
            return (
              <div className={`time-row${i === weekDay ? " bold" : ""}`}>
                <div className="day">{days[i]}</div>
                <div className="from">{formatTime(h.open)}</div>
                <div className="to">{formatTime(h.close)}</div>
              </div>
            );
          })}
        </div>
        <div className="note">
          Les heures de livraisons et/ou d'emportés peuvent varier des <br />
          heures d'ouverture des commandes.
        </div>
      </div>
    );
  }

  return (
    <div className="closed">
      <div className="closed-msg">
        <p>{closedMsg}</p>
      </div>
      <div className="time-container">
        <h3>Heure d'ouverture des commandes</h3>
        {hours.map((h, i) => {
          if (h.isClosed) {
            return (
              <div className={`time-row${i === weekDay ? " bold" : ""}`}>
                <div className="day">{days[i]}</div>
                <div className="from">Fermé</div>
                <div className="to">Fermé</div>
              </div>
            );
          }
          return (
            <div className={`time-row${i === weekDay ? " bold" : ""}`}>
              <div className="day">{days[i]}</div>
              <div className="from">{formatTime(h.open)}</div>
              <div className="to">{formatTime(h.close)}</div>
            </div>
          );
        })}
      </div>
      <div className="note">
        Les heures de livraisons et/ou d'emportés peuvent varier des <br />
        heures d'ouverture des commandes.
      </div>
    </div>
  );
};

export default FormClosed;
