import React from "react";
import List from "@material-ui/core/List";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Currency from "currency.js";
import Category from "./Category";

const Menu = () => {
  const {
    companyPicture,
    menuHeader,
    MenuFooter,
    minimumDeliveryCost,
    hasDelivery
  } = useSelector(state => state.settings);

  const categories = useSelector(state => state.purchase.categories);

  const MiniminimumDeliveryCost = () => {
    const theme = useTheme();
    if (minimumDeliveryCost.value && hasDelivery.value) {
      return (
        <div
          className="minimumDeliveryCost"
          style={{
            width: "100%",
            fontWeight: 100,
            textAlign: "center",
            padding: "0 30px",
            fontSize: "12px",
            color: theme.palette.primary.main
          }}
        >
          {`Afin de passer une livraison, le sous-total minimum requis est de ${Currency(
            minimumDeliveryCost.value
          ).format(false)}$`}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="menu-logo">
        <img
          height={250}
          style={{
            margin: "0 auto",
            width: "auto",
            padding: "50px 0",
            display: " block"
          }}
          src={companyPicture.path}
          alt="company logo"
        />
      </div>
      <List disablePadding>
        <ListItem>
          <div
            style={{
              width: "100%",
              fontWeight: 100,
              textAlign: "center",
              padding: "0 30px",
              fontSize: "12px"
            }}
          >
            {menuHeader.value}
          </div>
        </ListItem>
        <ListItem>
          <MiniminimumDeliveryCost />
        </ListItem>
        {categories.map(category => {
          return (
            <Category
              key={category.id}
              title={category.title}
              isAlcool={category.isAlcool}
              description={category.description}
            />
          );
        })}
        <ListItem>
          <div
            style={{
              width: "100%",
              fontWeight: 100,
              textAlign: "center",
              padding: "0 30px",
              fontSize: "12px"
            }}
          >
            {MenuFooter.value}
          </div>
        </ListItem>
      </List>
    </>
  );
};

export default Menu;
