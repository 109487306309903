import React, { useReducer, useEffect } from "react";
import socket from "../../../socket/socketIOAdmin";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import { ListSubheader } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Discount from "./Discount";
import CasinoIcon from "@material-ui/icons/Casino";
import { v4 as uuidv4 } from "uuid";

const Discounts = () => {
  const [state, dispatch] = useReducer(reducer, []);
  useEffect(() => {
    socket().emit("get-discounts", {}, response => {
      const { discounts } = response;
      dispatch({ type: "init", data: discounts });
    });
  }, []);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      style={{ marginBottom: 100 }}
    >
      <Grid
        item
        xs={12}
        md={8}
        style={{ marginTop: 100, marginBottom: 40, maxWidth: "100%" }}
      >
        <div className="menu-container">
          <List>
            <ListSubheader
              style={{ backgroundColor: "white", color: "#6e6e6e" }}
            >
              <Typography
                variant="h6"
                style={{
                  marginTop: 20,
                  paddingBottom: 10,
                  paddingTop: 10,
                  color: "black",
                  borderBottom: "1px #252525 solid",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                Promotion
                <div className="action-bar">
                  <Tooltip
                    title="Générer un code unique"
                    aria-label="Générer un code unique"
                  >
                    <IconButton
                      onClick={() => {
                        socket().emit(
                          "create-discount",
                          {
                            key: uuidv4().split("-")[0],
                            type: "$",
                            quantity: 1
                          },
                          item => {
                            dispatch({
                              type: "create",
                              item: item[0]
                            });
                          }
                        );
                      }}
                    >
                      <CasinoIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ajouter un item" aria-label="Ajouter un item">
                    <IconButton
                      edge="end"
                      onClick={() => {
                        socket().emit(
                          "create-discount",
                          {
                            key: `promo-${state.length + 1}`,
                            type: "%"
                          },
                          item => {
                            dispatch({
                              type: "create",
                              item: item[0]
                            });
                          }
                        );
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Typography>
            </ListSubheader>
            {state.map(item => {
              return (
                <Discount
                  deleteItem={() => {
                    socket().emit(
                      "delete-discount",
                      {
                        id: item.id
                      },
                      () => {
                        dispatch({
                          type: "delete",
                          id: item.id
                        });
                      }
                    );
                  }}
                  edit={(toBeEdited, cb) => {
                    socket().emit(
                      "edit-discount",
                      {
                        id: item.id,
                        ...toBeEdited
                      },
                      editedDiscount => {
                        if (cb) {
                          cb();
                        }

                        dispatch({
                          type: "edit",
                          item: editedDiscount[0]
                        });
                      }
                    );
                  }}
                  key={item.id}
                  discount={item}
                />
              );
            })}
          </List>
        </div>
      </Grid>
    </Grid>
  );
};

export default Discounts;

const reducer = (state, action) => {
  switch (action.type) {
    case "init":
      return action.data;
    case "create":
      return [...state, action.item];
    case "delete":
      return state.filter(item => item.id !== action.id);
    case "edit":
      return [
        ...state.map(item => {
          if (item.id === action.item.id) {
            return action.item;
          } else {
            return item;
          }
        })
      ];
    default:
      return state;
  }
};
