import store from "../Store/RootReducer";

export default amount => {
  const { settings } = store.getState();
  let takeoutFee;
  if (settings.takeoutFee !== undefined) {
    if (amount < settings.minimumPriceForFreeTakeout.value) {
      takeoutFee = settings.takeoutFee.value;
    } else {
      takeoutFee = 0;
    }
  }

  if (takeoutFee === null) {
    throw new Error("Something went wrong in getTakeoutFee.js");
  }

  return takeoutFee;
};
