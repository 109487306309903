const reducer = (state, action) => {
  const newTakeOut = (state, action, key) => {
    if (state[key]) {
      return { ...state, [key]: [...state[key], action.order] };
    }
    return { ...state, [key]: [action.order] };
  };

  switch (action.type) {
    case "initTakeOuts":
      return action.data;
    case "initDeliveries":
      return action.data;
    case "archiveOrder":
      let k = action.data.key;

      const filteredOrders = state[k].filter(
        order => order.id !== action.data.id
      );
      if (filteredOrders.length) {
        return {
          ...state,
          [k]: state[k].filter(order => order.id !== action.data.id)
        };
      }
      const newState = { ...state };
      delete newState[k];
      return newState;
    case "newDelivery":
      return newTakeOut(state, action, action.order.options.address.city);
    case "newOrder":
      return newTakeOut(state, action, action.order.pickupTime);
    case "newPam":
      return newTakeOut(state, action, action.order.pickupDate);
    default:
      return state;
  }
};
export default reducer;
