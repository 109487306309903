import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmDialog from "../../../components/ConfirmDialog";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { toast } from "react-toastify";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Currency from "currency.js";

const Discount = ({ discount, deleteItem, edit }) => {
  const [isEdit, setEdit] = useState(discount.isEdit);
  const [key, setKey] = useState(discount.key);
  const [amount, setAmount] = useState(discount.amount / 100);
  const [quantity, setQuantity] = useState(discount.quantity);
  const [type, setType] = useState(discount.type);
  const [open, setDialog] = useState(false);
  if (isEdit) {
    return (
      <>
        <ListItem style={{ flexWrap: "wrap" }}>
          <TextField
            style={{ flex: 1, minWidth: 150 }}
            value={key}
            onChange={e => setKey(e.target.value.replace(" ", ""))}
            label="Clé"
          />
          <TextField
            style={{ flex: 1, minWidth: 50, marginLeft: 20 }}
            value={amount}
            onChange={e => {
              if (type === "%") {
                if (e.target.value > 100) {
                  setAmount(100);
                  toast.error("La type est en % donc doit être entre 0 et 100");
                  return;
                }
              }
              if (e.target.value >= 0) {
                setAmount(e.target.value);
              } else {
                toast.error("Le rabais doit être positif");
                setAmount(0);
              }
            }}
            label="Valeur"
          />
          <TextField
            style={{ flex: 1, minWidth: 50, marginLeft: 20 }}
            value={quantity === null ? "" : quantity}
            type="number"
            onChange={e => {
              if (e.target.value >= 0) {
                setQuantity(e.target.value);
              } else {
                toast.error("La quantité disponble doit être plus grand que 0");
                setQuantity("");
              }
            }}
            label="Quantité"
          />
          <FormControl style={{ flex: 1, maxWidth: 100, marginLeft: 20 }}>
            <InputLabel id="discount-type-label">Type</InputLabel>
            <Select
              labelId="discount-type-label"
              id="discount-type"
              value={type}
              onChange={e => {
                setType(e.target.value);
                if (e.target.value === "%" && amount > 100) {
                  setAmount(0);
                }
              }}
            >
              <MenuItem value={"%"}>%</MenuItem>
              <MenuItem value={"$"}>$</MenuItem>
            </Select>
          </FormControl>
          <div className="quantity-wrapper" style={{ minWidth: 0 }}>
            <Tooltip
              title="Sauvegarder"
              onClick={() =>
                edit(
                  {
                    key,
                    amount: Currency(amount).multiply(100),
                    quantity,
                    type
                  },
                  () => setEdit(false)
                )
              }
              aria-label="Sauvegarder"
            >
              <IconButton edge="end">
                <DoneIcon />
              </IconButton>
            </Tooltip>
          </div>
        </ListItem>
      </>
    );
  } else {
    return (
      <>
        <ListItem style={{ flexWrap: "wrap" }}>
          <ListItemText
            primary={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration:
                    discount.isDisabled || quantity === 0 ? "line-through" : ""
                }}
              >{`${key} - ${amount}${type}`}</div>
            }
            style={{
              flex: 1,
              minWidth: 150
            }}
          />

          <div className="quantity-wrapper">
            <Tooltip title="Disponibilité" aria-label="Disponibilité">
              <IconButton
                onClick={() => edit({ isDisabled: !discount.isDisabled })}
              >
                {!discount.isDisabled ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Modifier" aria-label="Modifier">
              <IconButton onClick={() => setEdit(true)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Effacer" aria-label="Effacer">
              <IconButton edge="end" onClick={() => setDialog(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </ListItem>
        {open ? (
          <ConfirmDialog
            open={open}
            handleClose={() => setDialog(false)}
            callback={deleteItem}
            message={"Voulez-vous effacer de façon permanente la promotion ?"}
            title={"Attention"}
          />
        ) : null}
      </>
    );
  }
};

export default Discount;
