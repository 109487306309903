import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import deepPurple from "@material-ui/core/colors/deepPurple";
import { useSelector } from "react-redux";
import { Elements } from "react-stripe-elements";
import { StripeProvider } from "react-stripe-elements";
import SettingsHandler from "./components/SettingsHandler";
import SecureRoute from "./components/SecureRoute";
import initPixel from "./Pixel/pixel";

//Pages
import Purchase from "./pages/Purchase/Purchase";
import Footer from "./components/Footer";

const darkTheme = createMuiTheme({
  palette: {
    primary: deepPurple
  }
});
const blueTheme = createMuiTheme();
export default () => {
  const { isInit, stripeKey, theme, facebookPixelID } = useSelector(
    state => state.settings
  );

  if (!isInit) {
    return <SettingsHandler />;
  }

  if (facebookPixelID.value !== "") {
    initPixel(facebookPixelID.value);
  }

  const stripeProps = { apiKey: stripeKey.value };
  return (
    <StripeProvider {...stripeProps}>
      <ThemeProvider theme={theme.color === "blue" ? blueTheme : darkTheme}>
        {facebookPixelID.value !== "" && (
          <img
            height="1"
            width="1"
            style={{ display: "none" }}
            src={`https://www.facebook.com/tr?id=${facebookPixelID.value}&ev=PageView&noscript=1`}
          />
        )}
        <Router>
          <Switch>
            <Route exact path="/">
              <Suspense fallback={<></>}>
                <Elements
                  fonts={[
                    {
                      cssSrc:
                        "https://fonts.googleapis.com/css?family=Roboto:300&display=swap"
                    }
                  ]}
                >
                  <Purchase />
                </Elements>
              </Suspense>
            </Route>
            <SecureRoute path="/" />
          </Switch>
        </Router>
        <Footer />
      </ThemeProvider>
    </StripeProvider>
  );
};
