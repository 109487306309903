export default () => {
  const key =
    process.env.REACT_APP_FORCE_ENV === "production"
      ? window.location.host
      : "dev";

  const restaurants = {
    dev: {
      backendEndPoint: () => "http://localhost:8080/",
      promotionNow: () => "Don pour les travailleurs de la santé"
    },
    "boucherie.intekplus.app": {
      backendEndPoint: () => "https://order-boucherie.herokuapp.com/"
    },
    "boulangerie.intekplus.app": {
      backendEndPoint: () => "https://order-boulangerie.herokuapp.com/"
    },
    "commande.intekplus.app": {
      backendEndPoint: () => "https://in-order.herokuapp.com/"
    },
    "demo.pizzli.ca": {
      backendEndPoint: () => "https://in-order.herokuapp.com/"
    },
    "pizzli.now.sh": {
      backendEndPoint: () => "https://in-order.herokuapp.com/"
    },
    "othym.pizzli.ca": {
      backendEndPoint: () => "https://othym.herokuapp.com/"
    },
    "pizzeria-heirloom.pizzli.ca": {
      backendEndPoint: () => "https://heirloom-pizzeria.herokuapp.com/"
    },
    "central.pizzli.ca": {
      backendEndPoint: () => "https://heirloom-central.herokuapp.com/"
    },
    "em.pizzli.ca": {
      backendEndPoint: () => "https://etat-major.herokuapp.com/"
    },
    "qg.pizzli.ca": {
      backendEndPoint: () => "https://quartier-general.herokuapp.com/"
    },
    "levicecache.pizzli.ca": {
      backendEndPoint: () => "https://vice-cache.herokuapp.com/"
    },
    "ho-lee-chix.pizzli.ca": {
      backendEndPoint: () => "https://ho-lee-chix.herokuapp.com/"
    },
    "limoges67.pizzli.ca": {
      backendEndPoint: () => "https://limoges67.herokuapp.com/"
    },
    "lecoupmonte.pizzli.ca": {
      backendEndPoint: () => "https://blcm.herokuapp.com/"
    },
    "lecoupmonte-pam.pizzli.ca": {
      backendEndPoint: () => "https://blcm-pam.herokuapp.com/",
      promotionNow: () => "Don pour les travailleurs de la santé"
    },
    "cafelesfraiches.pizzli.ca": {
      backendEndPoint: () => "https://cafelesfraiches.herokuapp.com/"
    },
    "dieuduciel-stjerome.pizzli.ca": {
      backendEndPoint: () => "https://ddc-stj.herokuapp.com/"
    },
    "dieuduciel-montreal.pizzli.ca": {
      backendEndPoint: () => "https://ddc-mtl.herokuapp.com/"
    },
    "levicecache-bistro.pizzli.ca": {
      backendEndPoint: () => "https://vice-cache-bistro.herokuapp.com/"
    },
    "lescanailles.pizzli.ca": {
      backendEndPoint: () => "https://lescanailles.herokuapp.com/"
    },
    "ritarestaurant.pizzli.ca": {
      backendEndPoint: () => "https://rita-restaurant.herokuapp.com/"
    },
    "restaurantwellington.pizzli.ca": {
      backendEndPoint: () => "https://wellington-restaurant.herokuapp.com/"
    },
    "aupetitextra.pizzli.ca": {
      backendEndPoint: () => "https://au-petit-extra.herokuapp.com/"
    },
    "boucheriesalaisonlimoges.pizzli.ca": {
      backendEndPoint: () => "https://limoges-extra.herokuapp.com/"
    },
    "amereaboire.pizzli.ca": {
      backendEndPoint: () => "https://amereaboire.herokuapp.com/"
    },
    "racinesbistro.pizzli.ca": {
      backendEndPoint: () => "https://racinesbistro.herokuapp.com/"
    }
  };

  return restaurants[key];
};
