import React from "react";

const Footer = () => {
  return (
    <footer>
      <span>Propulsé par</span>
      <a
        href="http://fb.me/pizzli.ca"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/logo_pizzli.svg" alt="Logo Pizzli" />
      </a>
    </footer>
  );
};

export default Footer;
