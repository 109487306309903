import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MenuBar from "../../../components/MenuBar";
//pages
import AllTakeOuts from "../Orders/All/AllTakeOuts";
import Menu from "../Menu/Menu";
import AllDeliveries from "../Orders/All/AllDeliveries";
import AllPams from "../Orders/All/AllPams";
import Dashboard from "../Dashboard/Dashboard";
import WeekReport from "../WeekReport/WeekReport";
import Settings from "../Settings/Settings";
import Users from "../Users/Users";
import Discounts from "../Discount/Discounts";
import SecureRoute from "./SecureRoute";
import PamRoute from "./PamRoute";

const AdminLayout = () => {
  return (
    <>
      <MenuBar />
      <Suspense fallback={<></>}>
        <div className="admin-container">
          <Switch>
            <SecureRoute path="/dashboard">
              <Dashboard />
            </SecureRoute>
            <SecureRoute path="/settings">
              <Settings />
            </SecureRoute>
            <SecureRoute path="/users">
              <Users />
            </SecureRoute>
            <SecureRoute path="/discounts">
              <Discounts />
            </SecureRoute>
            <PamRoute path="/take-outs">
              <AllTakeOuts />
            </PamRoute>
            <PamRoute path="/deliveries">
              <AllDeliveries />
            </PamRoute>
            <PamRoute path="/week-report">
              <WeekReport />
            </PamRoute>
            <SecureRoute path="/menu">
              <Menu />
            </SecureRoute>
            <Route path="/pams">
              <AllPams />
            </Route>
            <Route path="/pam-report">
              <WeekReport isPam />
            </Route>
            <Redirect to="/dashboard" />
          </Switch>
        </div>
      </Suspense>
    </>
  );
};

export default AdminLayout;
