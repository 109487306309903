import React, { useCallback } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import convertTime from "../../../utils/convertTime";
import { useDispatch, useSelector } from "react-redux";
import Subheader from "../UI/Subheader";

const FormHour = () => {
  const reduxDispatch = useDispatch();

  const orderType = useSelector(
    state => state.purchase.orderDetails.type.value
  );
  const hours = useSelector(state => state.purchase.hours);
  const orderHour = useSelector(state => state.purchase.orderDetails.hour);
  const status = useSelector(state => state.purchase.options.status);
  const { deliveryWhenPossible } = useSelector(state => state.settings);

  const getHours = () => {
    if (orderType === "delivery") {
      return Object.keys(hours.deliveryHoursAvailbality).map(hour => {
        const hourIsFull = !hours.deliveryHoursAvailbality[hour];
        if (hourIsFull && process.env.REACT_APP_FORCE_ENV === "production") {
          return (
            <MenuItem disabled key={hour} value={hour}>
              {`${convertTime(hour)} (Limite atteinte)`}
            </MenuItem>
          );
        } else {
          return (
            <MenuItem key={hour} value={hour}>
              {convertTime(hour)}
            </MenuItem>
          );
        }
      });
    }

    return Object.keys(hours.hoursAvailbality).map(hour => {
      const hourIsFull = !hours.hoursAvailbality[hour];
      if (hourIsFull && process.env.REACT_APP_FORCE_ENV === "production") {
        return (
          <MenuItem disabled key={hour} value={hour}>
            {`${convertTime(hour)} (Limite atteinte)`}
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={hour} value={hour}>
            {convertTime(hour)}
          </MenuItem>
        );
      }
    });
  };

  const handlePickupHour = useCallback(
    ({ target: { value } }) => {
      reduxDispatch({
        type: "[PURCHASE] set details",
        data: { value, id: "hour", valid: true }
      });
    },
    [reduxDispatch]
  );

  const title =
    orderType === "takeout" ? "Heure de ramassage" : "Heure de livraison";

  if (status !== undefined && status !== "OK") {
    return null;
  }
  if (deliveryWhenPossible.value && orderType === "delivery") {
    return null;
  }
  return (
    <>
      <Subheader>{title}</Subheader>
      <FormControl
        error={orderHour.valid !== undefined && !orderHour.valid}
        fullWidth
        variant="outlined"
        style={{ marginTop: 20 }}
      >
        <InputLabel>Sélectionnez votre heure</InputLabel>
        <Select
          value={orderHour.value}
          onChange={handlePickupHour}
          label="Sélectionnez votre heure"
        >
          {getHours()}
        </Select>
        {orderHour.valid !== undefined && !orderHour.valid && (
          <FormHelperText>Veuillez choisir une heure</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default FormHour;
