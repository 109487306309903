import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import AuthReducer from "./reducers/Auth-Reducer";
import NotifReducer from "./reducers/Notif-Reducer";
import SettingReducer from "./reducers/Setting-Reducer";
import PurchaseReducer from "./reducers/Purchase-Reducer";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: AuthReducer,
  notif: NotifReducer,
  settings: SettingReducer,
  purchase: PurchaseReducer
});

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
