import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Subheader from "../UI/Subheader";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useDebouncedCallback } from "use-debounce";
import Socket from "../../../socket/socketIO";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import { useRef } from "react";

const styles = theme => ({
  primary: {
    color: theme.palette.primary.main
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  }
});

const FormDiscount = ({ classes }) => {
  const reduxDispatch = useDispatch();

  const { key, value, type, valid, message } = useSelector(
    state => state.purchase.options.discount
  );

  const [code, setCode] = useState(key);
  const [loading, setLoading] = useState(false);
  const _lastCode = useRef("");

  const setDiscount = discount => {
    reduxDispatch({
      type: "[PURCHASE] set options",
      data: { discount }
    });
  };

  const validate = key => {
    if (_lastCode.current !== code) {
      _lastCode.current = code;
      Socket().emit(
        "validate-discount",
        {
          key
        },
        data => {
          setDiscount(data);
          setLoading(false);
        }
      );
    }
  };

  const [debouncedCallback] = useDebouncedCallback(key => validate(key), 2000);

  const onChange = useCallback(
    ({ target: { value } }) => {
      setCode(value);
      if (!loading) {
        setLoading(true);
      }
      debouncedCallback(value);
    },
    [debouncedCallback, loading]
  );

  const onKeyUp = e => {
    if (e.keyCode === 13) {
      validate(code);
    }
  };

  const SubTextStatus = () => {
    if (valid === undefined) {
      return (
        <span>Le code sera validé automatiquement à la fin de la saisi</span>
      );
    } else if (!valid) {
      if (message === "discount_disabled") {
        return <span className={classes.error}>Ce code n'est plus actif</span>;
      } else if (message === "discount_unknown") {
        return (
          <span className={classes.error}>
            Malheureusement, ce code n'existe pas
          </span>
        );
      } else if (message === "discount_out_of_stock") {
        return (
          <span className={classes.error}>Ce code n'est plus disponible</span>
        );
      }
      return <span className={classes.error}>Error, something went wrong</span>;
    } else {
      return (
        <span className={classes.primary}>{`Un rabais de ${value /
          100}${type} est appliqué sur votre facture`}</span>
      );
    }
  };

  const Adornment = () => {
    if (loading) {
      return (
        <InputAdornment position="end">
          <CircularProgress style={{ width: 19, height: 19 }} />
        </InputAdornment>
      );
    } else if (valid === undefined) {
      return null;
    } else if (valid) {
      return (
        <InputAdornment position="end">
          <CheckIcon className={classes.success} />
        </InputAdornment>
      );
    }

    return (
      <InputAdornment position="end">
        <CloseIcon className={classes.error} />
      </InputAdornment>
    );
  };

  return (
    <>
      <Subheader style={{ marginTop: 20 }}>
        Avez-vous un code promotionnel?
      </Subheader>
      <TextField
        onKeyUp={onKeyUp}
        disabled={valid}
        id="code"
        style={{ marginTop: 20 }}
        placeholder="Entrez votre code ici"
        value={code}
        variant="outlined"
        fullWidth
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Adornment />
            </InputAdornment>
          )
        }}
      />
      <FormHelperText
        style={{
          width: "100%",
          textAlign: "center",
          minHeight: 19
        }}
      >
        <SubTextStatus />
      </FormHelperText>
    </>
  );
};

export default withStyles(styles)(FormDiscount);
