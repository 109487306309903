import React, { useCallback } from "react";
import Subheader from "../UI/Subheader";
import TextField from "@material-ui/core/TextField";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

const FormNote = () => {
  const theme = useTheme();
  const reduxDispatch = useDispatch();

  const note = useSelector(state => state.purchase.orderDetails.note);
  const isInPamMode = useSelector(state => state.settings.isInPamMode.value);
  const status = useSelector(state => state.purchase.options.status);

  const updateNote = useCallback(
    ({ target: { value } }) =>
      reduxDispatch({
        type: "[PURCHASE] set details",
        data: { value, id: "note" }
      }),
    [reduxDispatch]
  );

  if (status !== undefined && status !== "OK") {
    return null;
  }

  return (
    <>
      <Subheader>Note</Subheader>
      {isInPamMode && (
        <p className="notice" style={{ color: theme.palette.primary.main }}>
          Notez à quel endroit la commande doit être laissé en cas d'absence
        </p>
      )}
      <TextField
        style={{ marginTop: 20 }}
        label="Note"
        placeholder="Note"
        value={note.value}
        multiline
        variant="outlined"
        fullWidth
        rows={4}
        onChange={updateNote}
      />
    </>
  );
};

export default FormNote;
