import React from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Subheader from "../../UI/Subheader";
import { useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

const FormAddressStandard = () => {
  const theme = useTheme();
  const reduxDispatch = useDispatch();

  const deliveryCities = useSelector(
    state => state.settings.deliveryCities.value
  );

  const street = useSelector(state => state.purchase.address.street);
  const city = useSelector(state => state.purchase.address.city);
  const state = useSelector(state => state.purchase.address.state);
  const zip = useSelector(state => state.purchase.address.zip);

  const onChange = ({ target }) => {
    reduxDispatch({
      type: "[PURCHASE] set address",
      data: { value: target.value, id: target.id }
    });
  };

  const handleCity = ({ target: { value } }) =>
    onChange({ target: { value, id: "city" } });

  return (
    <>
      <Subheader>Adresse</Subheader>
      <TextField
        error={street.valid !== undefined && !street.valid}
        id="street"
        style={{ marginTop: 20 }}
        label="Rue"
        placeholder="Rue"
        value={street.value}
        variant="outlined"
        fullWidth
        onChange={onChange}
      />
      <FormControl
        error={city.valid !== undefined && !city.valid}
        fullWidth
        variant="outlined"
        style={{ marginTop: 20 }}
      >
        <InputLabel>Ville</InputLabel>
        <Select value={city.value} onChange={handleCity} label="Ville">
          {deliveryCities.map(city => {
            const value = city.toLowerCase();
            return (
              <MenuItem key={value} value={value}>
                {city}
              </MenuItem>
            );
          })}
        </Select>
        {city.valid !== undefined && !city.valid && (
          <FormHelperText>Veuillez choisir une ville</FormHelperText>
        )}
      </FormControl>
      <div className="side-inputs">
        <TextField
          id="state"
          style={{ marginTop: 20 }}
          label="Province"
          placeholder="Province"
          value={state.value}
          variant="outlined"
          disabled
        />
        <TextField
          error={zip.valid !== undefined && !zip.valid}
          id="zip"
          style={{ marginTop: 20 }}
          label="Code postal"
          placeholder="Code postal"
          value={zip.value}
          variant="outlined"
          onChange={onChange}
        />
      </div>
      <p className="notice" style={{ color: theme.palette.primary.main }}>
        Si votre ville ne figure pas dans la liste, veuillez nous contacter par
        téléphone
      </p>
    </>
  );
};

export default FormAddressStandard;
