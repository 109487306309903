import React from "react";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import FormBottomActions from "../BottomActions/FormBottomActions";

const MainGrid = ({ children, onClick, onKeyPress }) => {
  return (
    <Fade timeout={500} in>
      <Grid
        container
        style={{ marginBottom: 100, flex: 1, display: "block" }}
        onClick={onClick}
        onKeyPress={onKeyPress}
      >
        {children}
        <FormBottomActions />
      </Grid>
    </Fade>
  );
};

export default MainGrid;
