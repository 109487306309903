import React, { useCallback } from "react";
import Box from "@material-ui/core/Box";
import { withStyles, useTheme } from "@material-ui/core/styles";
import BeenhereIcon from "@material-ui/icons/Beenhere";

const styles = theme => ({
  selected: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  }
});

const ChoiceBox = ({
  id,
  selected,
  title,
  subTitle,
  img,
  onClick,
  classes,
  disabled
}) => {
  const theme = useTheme();

  const onChange = useCallback(() => onClick(id), [onClick, id]);

  if (disabled) {
    return (
      <Box
        className="custom-input-box disabled"
        id={id}
        variant="outlined"
        border={1}
        borderRadius={4}
      >
        {img}
        <BeenhereIcon />
        <span>
          {title}
          {<small>(Non-disponible)</small>}
        </span>
      </Box>
    );
  }

  return (
    <Box
      onClick={onChange}
      className={`custom-input-box${
        selected ? ` selected ${classes.selected}` : ""
      }`}
      id={id}
      variant="outlined"
      border={1}
      borderColor={selected ? theme.palette.primary.main : null}
      borderRadius={4}
    >
      {img}
      <BeenhereIcon />
      <span>
        {title}
        {subTitle && <small>{subTitle}</small>}
      </span>
    </Box>
  );
};

export default withStyles(styles)(ChoiceBox);
