import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Socket from "../../../socket/socketIOAdmin";
import Stat from "./Stat";
import Currency from "currency.js";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import DateTime from "luxon/src/datetime.js";

const OrdersSalesStats = ({ fromDate, toDate }) => {
  const [stats, setStats] = useState(0);
  const { isInPamMode } = useSelector(state => state.settings);

  useEffect(() => {
    if (isInPamMode.value) {
      Socket().emit(
        "get-pams-sales",
        { fromDate: fromDate.toISO(), toDate: toDate.toISO() },
        response => {
          setStats(response);
        }
      );
    } else {
      Socket().emit(
        "get-orders-sales",
        {
          fromDate: fromDate.toISO(),
          toDate: toDate.toISO()
        },
        response => {
          setStats(response);
        }
      );
    }
  }, [fromDate, toDate, isInPamMode.value]);

  const weekOrDay = isInPamMode.value ? "semaine" : "journée";
  let lastOrderTime = stats.lastOrderTime
    ? DateTime.fromISO(stats.lastOrderTime).toLocaleString(DateTime.TIME_SIMPLE)
    : "N/A";
  return (
    <>
      <Stat
        title="Nombre de commandes"
        subHeader={stats.ordersCount}
        icon={<FileCopyIcon />}
        color="orange"
        footer={`Dernière commande à ${lastOrderTime}`}
      />
      <Stat
        title={`Ventes de la ${weekOrDay}`}
        subHeader={`${Currency(stats.subtotalNoDeliveriesNoTaxes)
          .divide(100)
          .format(false)}$`}
        icon={<MonetizationOnIcon />}
        color="green"
        footer={"Excluant les taxes et les frais de livraison"}
      />
      <Stat
        title={`Pourboires de la ${weekOrDay}`}
        subHeader={`${Currency(stats.takeoutDollarsTips)
          .add(stats.deliveryDollarsTips)
          .divide(100)
          .format(false)}$`}
        icon={<ThumbUpIcon />}
        color="blue"
        footer={`Emporter: ${Currency(stats.takeoutDollarsTips)
          .divide(100)
          .format(false)}$ - Livraison: ${Currency(stats.deliveryDollarsTips)
          .divide(100)
          .format(false)}$ - Pour une moyenne de ${stats.tipsAverage || 0}%`}
      />
      <Stat
        title="Frais de livraisons"
        subHeader={`${Currency(stats.deliveriesFee)
          .divide(100)
          .format(false)}$`}
        icon={<LocalShippingIcon />}
        color="red"
        footer={`Pour ${stats.deliveryCount} livraison(s)`}
      />
      <Stat
        title="Moyenne de factures"
        subHeader={`${Currency(stats.subtotalNoDeliveriesNoTaxes)
          .divide(stats.ordersCount || 1)
          .divide(100)
          .format(false)}$`}
        icon={<AssignmentLateIcon />}
        color="grey"
        footer={`Emportés et livraisons (excluant frais et taxes)`}
      />
    </>
  );
};

export default OrdersSalesStats;
