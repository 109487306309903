import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Subheader from "../UI/Subheader";
import { useDispatch, useSelector } from "react-redux";

const FormTip = () => {
  const reduxDispatch = useDispatch();
  const { choices: tips } = useSelector(state => state.settings.tipOptions);
  const tip = useSelector(state => state.purchase.orderDetails.tip.value);

  const handleTipChange = ({ target: { value } }) => {
    reduxDispatch({
      type: "[PURCHASE] set details",
      data: { value: Number.parseFloat(value), id: "tip" }
    });
  };

  return (
    <div className="form-tip">
      <Subheader>Pourboire</Subheader>
      <FormControl
        component="fieldset"
        style={{
          marginTop: 10,
          width: "100%"
        }}
      >
        <RadioGroup
          row
          aria-label="pourboire"
          name="pourboire"
          onChange={handleTipChange}
          value={tip}
          style={{
            display: "flex",
            justifyContent: "space-evenly"
          }}
        >
          {tips.map(t => (
            <FormControlLabel
              key={t}
              value={t}
              control={<Radio color="primary" />}
              label={`${t * 100}%`}
            />
          ))}
          <FormControlLabel
            value={0}
            control={<Radio color="primary" />}
            label="Aucun"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default FormTip;
