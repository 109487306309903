import React, { useReducer, useEffect, useState } from "react";
import socket from "../../../socket/socketIOAdmin";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import MenuItem from "./MenuItem";
import MenuSection from "./MenuSection";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import Description from "./Description";
import { toast } from "react-toastify";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (
  source,
  destination,
  droppableSource,
  droppableDestination,
  keys
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, {
    ...removed,
    category: keys[droppableDestination.droppableId]
  });

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const Menu = () => {
  const [state, dispatch] = useReducer(reducer, null);
  const [categories, setCategories] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { menuHeader, MenuFooter } = useSelector(state => state.settings);
  const [expanded, setExtented] = useState(true);
  useEffect(() => {
    socket().emit("get-menu", {}, response => {
      const { menu } = response;
      dispatch({ type: "initMenu", data: menu });
      setTimeout(() => {
        setLoading(false);
      }, 100);
    });
    socket().emit("get-categories", {}, response => {
      const { categories } = response;
      setCategories(categories);
    });
  }, []);

  const getList = id => (state[id] ? state[id] : []);
  const categoriesObject = {};
  categories.forEach(c => (categoriesObject[c.title] = c.id));
  const onDragEnd = result => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    if (result.draggableId.includes("category")) {
      const items = reorder(categories, source.index, destination.index);
      setCategories(items);
      socket().emit("update-index-categories", items, () => {
        setExtented(true);
      });

      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      socket().emit(
        "update-index-item",
        {
          items: { [source.droppableId]: items }
        },
        resp => {
          if (!resp.success) {
            toast.error(
              "Impossible de mettre à jour le menu. Veuillez rafraichir la page."
            );
          }
        }
      );

      dispatch({
        type: "handleDnD",
        result: {
          [source.droppableId]: items.map((item, index) => ({
            ...item,
            index
          }))
        }
      });
    } else {
      const switchResults = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
        categoriesObject
      );

      socket().emit("update-index-item", { items: switchResults }, resp => {
        if (!resp.success) {
          toast.error(
            "Impossible de mettre à jour le menu. Veuillez rafraichir la page."
          );
        }
      });
      dispatch({ type: "handleDnD", result: switchResults });
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Fade in={!isLoading}>
        <Grid
          container
          direction="row"
          justify="center"
          style={{ marginBottom: 100 }}
        >
          <Grid
            item
            xs={12}
            md={8}
            style={{ marginTop: 100, marginBottom: 40, maxWidth: "100%" }}
          >
            <div className="menu-container" style={{ width: "100%" }}>
              <List>
                <Description
                  setting={menuHeader}
                  settingKey="menuHeader"
                  keyDescription={"Texte d'entête visible en haut du menu"}
                />
                <Droppable isDropDisabled={expanded} droppableId={"category"}>
                  {dropProvided => (
                    <div
                      {...dropProvided.droppableProps}
                      ref={dropProvided.innerRef}
                    >
                      {!categories.length || !state
                        ? null
                        : categories.map((category, catIndex) => {
                            return (
                              <Draggable
                                key={category.id}
                                index={catIndex}
                                draggableId={`category-${category.id}`}
                                isDragDisabled={expanded}
                              >
                                {dragProvided => (
                                  <div
                                    ref={dragProvided.innerRef}
                                    {...dragProvided.draggableProps}
                                  >
                                    <Droppable
                                      key={category.id}
                                      droppableId={category.title}
                                      isDropDisabled={!expanded}
                                    >
                                      {(provided, snapshot) => (
                                        <div ref={provided.innerRef}>
                                          <MenuSection
                                            handleDrag={setExtented}
                                            expanded={expanded}
                                            provided={dragProvided}
                                            handleCreate={() => {
                                              socket().emit(
                                                "create-item",
                                                {
                                                  category: category.id,
                                                  description: "",
                                                  price: 0,
                                                  isAvailable: false,
                                                  index: state[category.title]
                                                    ? state[category.title]
                                                        .length
                                                    : 0
                                                },
                                                newItem => {
                                                  dispatch({
                                                    type: "createMenuItem",
                                                    category: category.title,
                                                    item: newItem[0]
                                                  });
                                                }
                                              );
                                            }}
                                            handleDeleteCategory={() => {
                                              socket().emit(
                                                "delete-category",
                                                {
                                                  ...category
                                                },
                                                () => {
                                                  setCategories([
                                                    ...categories.filter(
                                                      c => c.id !== category.id
                                                    )
                                                  ]);
                                                }
                                              );
                                            }}
                                            categories={categoriesObject}
                                            category={category.title}
                                            isAlcool={category.isAlcool}
                                            description={category.description}
                                          />

                                          <ExpansionPanel
                                            expanded={expanded}
                                            style={{ boxShadow: "none" }}
                                          >
                                            <ExpansionPanelSummary
                                              style={{ display: "none" }}
                                            ></ExpansionPanelSummary>
                                            <ExpansionPanelDetails
                                              style={{
                                                padding: 0,
                                                display: "block",
                                                boxShadow: "none"
                                              }}
                                            >
                                              {(state[category.title]
                                                ? state[category.title]
                                                : []
                                              )
                                                .sort(
                                                  (a, b) => a.index - b.index
                                                )
                                                .map((item, i) => {
                                                  return (
                                                    <Draggable
                                                      key={item.id}
                                                      index={i}
                                                      draggableId={`${item.id}`}
                                                    >
                                                      {(provided, snapshot) => (
                                                        <div
                                                          className="menusection-wrapper"
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                        >
                                                          <MenuItem
                                                            provided={provided}
                                                            onSave={item =>
                                                              dispatch({
                                                                type:
                                                                  "updateMenuItem",
                                                                item,
                                                                category:
                                                                  category.title
                                                              })
                                                            }
                                                            Delete={item =>
                                                              dispatch({
                                                                type:
                                                                  "deleteMenuItem",
                                                                item,
                                                                category:
                                                                  category.title
                                                              })
                                                            }
                                                            item={item}
                                                          />
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  );
                                                })}
                                            </ExpansionPanelDetails>
                                          </ExpansionPanel>

                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                      {dropProvided.placeholder}
                    </div>
                  )}
                </Droppable>
                <Description
                  setting={MenuFooter}
                  settingKey="MenuFooter"
                  keyDescription={"Texte visible en bas du menu"}
                />
              </List>
            </div>
          </Grid>
          <div className="button-container">
            <Button
              onClick={() => {
                socket().emit(
                  "create-category",
                  {
                    title: "New Category - " + (categories.length + 1),
                    index: categories.length
                  },
                  category => {
                    setCategories([...categories, category]);
                  }
                );
              }}
              color="primary"
              variant="contained"
              size="large"
            >
              Ajouter une catégorie
            </Button>
            <Button
              onClick={() => setExtented(state => !state)}
              color="primary"
              variant="contained"
              size="large"
            >
              Changer l'ordre
            </Button>
          </div>
        </Grid>
      </Fade>
    </DragDropContext>
  );
};

export default Menu;

const reducer = (state, action) => {
  switch (action.type) {
    case "initMenu":
      return { ...state, ...action.data };
    case "handleDnD":
      return { ...state, ...action.result };
    case "updateMenuItem":
      const items = [...state[action.category]].map(item => {
        if (item.id === action.item.id) {
          return action.item;
        } else {
          return item;
        }
      });
      return { ...state, [action.category]: items };
    case "createMenuItem":
      if (state[action.category]) {
        return {
          ...state,
          [action.category]: [
            ...state[action.category],
            { ...action.item, isEdit: true }
          ]
        };
      } else {
        return {
          ...state,
          [action.category]: [{ ...action.item, isEdit: true }]
        };
      }
    case "deleteMenuItem":
      const filteredItems = [...state[action.category]].filter(
        item => item.id !== action.item.id
      );
      return { ...state, [action.category]: filteredItems };
    default:
      return state;
  }
};
