import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmDialog from "../../../components/ConfirmDialog";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import StarIcon from "@material-ui/icons/Star";
const User = ({ user, deleteUser, editUser }) => {
  const [isEdit, setEdit] = useState(user.isEdit);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [role, setRole] = useState(user.role);
  const [open, setDialog] = useState(false);
  const disabled = !!(user.id === 1 || user.id === 2);
  if (isEdit) {
    return (
      <>
        <ListItem style={{ flexWrap: "wrap" }}>
          <TextField
            style={{ flex: 1, minWidth: 150 }}
            value={email}
            onChange={e => setEmail(e.target.value.replace(" ", ""))}
            label="Nom"
          />
          <TextField
            style={{ flex: 1, minWidth: 150, marginLeft: 20 }}
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            label=" Nouveau mot de passe"
          />
          <FormControl style={{ flex: 1, maxWidth: 100, marginLeft: 20 }}>
            <InputLabel id="roleid">Role</InputLabel>
            <Select
              disabled={disabled}
              labelId="roleid"
              id="role-select"
              value={role}
              onChange={e => setRole(e.target.value)}
            >
              <MenuItem value={"admin"}>Admin</MenuItem>
              <MenuItem value={"employe"}>Employé</MenuItem>
            </Select>
          </FormControl>
          <div className="quantity-wrapper" style={{ minWidth: 0 }}>
            <Tooltip
              title="Sauvegarder"
              onClick={() =>
                editUser({ email, password, role }, () => setEdit(false))
              }
              aria-label="Sauvegarder"
            >
              <IconButton edge="end">
                <DoneIcon />
              </IconButton>
            </Tooltip>
          </div>
        </ListItem>
      </>
    );
  } else {
    return (
      <>
        <ListItem style={{ flexWrap: "wrap" }}>
          <ListItemText
            primary={
              <div style={{ display: "flex", alignItems: "center" }}>
                {email}{" "}
                {role === "admin" && <StarIcon style={{ paddingLeft: 30 }} />}
              </div>
            }
            style={{
              flex: 1,
              minWidth: 150
            }}
          />

          <div className="quantity-wrapper">
            <Tooltip title="Modifier" aria-label="Modifier">
              <IconButton onClick={() => setEdit(true)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Effacer" aria-label="Effacer">
              <IconButton
                disabled={disabled}
                edge="end"
                onClick={() => setDialog(true)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </ListItem>
        {open ? (
          <ConfirmDialog
            open={open}
            handleClose={() => setDialog(false)}
            callback={deleteUser}
            message={"Voulez-vous effacer de façon permanente l'utilisateur ?"}
            title={"Attention"}
          />
        ) : null}
      </>
    );
  }
};

export default User;
