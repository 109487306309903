import store from "../Store/RootReducer";

const areDuoSettingValid = () => {
  const { settings } = store.getState();
  const hasDelivery = settings.hasDelivery;
  const getDeliveryFee = settings.deliveryFee;
  const hasDynamicDeliveryFee = settings.hasDynamicDeliveryFee;

  if (
    (getDeliveryFee === undefined &&
      hasDynamicDeliveryFee === undefined &&
      hasDelivery !== undefined) ||
    (getDeliveryFee !== undefined &&
      hasDynamicDeliveryFee !== undefined &&
      hasDelivery === undefined)
  ) {
    return false;
  }
  return true;
};

const evaluateLimits = (
  amount,
  limit,
  deliveryFee,
  noDeliveryUnderLimit = false,
  forceMinimumFee = false
) => {
  const missing = limit - amount > 0 ? limit - amount : 0;
  if (noDeliveryUnderLimit && amount < limit) {
    return { value: `min_${limit}` };
  } else if (noDeliveryUnderLimit && forceMinimumFee) {
    return { value: deliveryFee, missing };
  } else if (amount < limit) {
    return { value: deliveryFee, missing };
  }
  return { value: 0, missing };
};

const getDynamicFee = (amount, km) => {
  let deliveryFee = null;
  if (km <= 15000) {
    deliveryFee = evaluateLimits(amount, 75, 10);
  } else if (km <= 25000) {
    deliveryFee = evaluateLimits(amount, 150, 15);
  } else if (km <= 35000) {
    deliveryFee = evaluateLimits(amount, 300, 20);
  } else if (km <= 50000) {
    deliveryFee = evaluateLimits(amount, 400, 25, true, true);
  } else {
    deliveryFee = { value: "out_of_range", missing: 0 };
  }
  return deliveryFee;
};

export const getDeliveryFeeWithMissingAmount = (amount, km) => {
  let deliveryFee = null;

  deliveryFee = getDynamicFee(amount, km);

  if (deliveryFee === null) {
    throw new Error("Something went wrong in getDeliveryFee.js");
  }

  return deliveryFee;
};

export default (amount, km = 0) => {
  const { settings } = store.getState();
  let deliveryFee = null;
  if (!areDuoSettingValid()) {
    throw new Error(
      'If "hasDelivery" is true, you need either "deliveryFee" or "hasDynamicDeliveryFee" setting to be defined'
    );
  } else if (
    settings.hasDynamicDeliveryFee !== undefined &&
    settings.hasDynamicDeliveryFee.value
  ) {
    deliveryFee = getDynamicFee(amount, km).value;
  } else if (settings.deliveryFee !== undefined) {
    if (amount < settings.minimumPriceForFreeDelivery.value) {
      deliveryFee = settings.deliveryFee.value;
    } else {
      deliveryFee = 0;
    }
  }

  if (deliveryFee === null) {
    throw new Error(
      "Something went wrong in getDeliveryFee.js default function"
    );
  }

  return deliveryFee;
};
