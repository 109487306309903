import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import MenuQuantityLeft from "./MenuQuantityLeft";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import socket from "../../../socket/socketIO";

const MenuQuantity = ({
  itemID,
  quantity,
  quantityLeft,
  category,
  isAlcool
}) => {
  const reduxDispatch = useDispatch();
  const { totalCountWithNoAlcool } = useSelector(state => state.purchase);
  const forceFoodWithAlcool = useSelector(
    state => state.settings.forceFoodWithAlcool.value
  );

  const [loading, isLoading] = useState(false);
  const shouldDisabledItem =
    forceFoodWithAlcool && isAlcool && totalCountWithNoAlcool === 0;

  const add = () => {
    if (quantityLeft != null && !loading) {
      if (quantityLeft > 0) {
        isLoading(true);
        socket().emit("lock-item", itemID, () => {
          reduxDispatch({
            type: "[PURCHASE] add",
            data: { item: { id: itemID, category, isAlcool } }
          });
          isLoading(false);
        });
      }
    } else {
      if (quantity + 1 <= 10) {
        reduxDispatch({
          type: "[PURCHASE] add",
          data: { item: { id: itemID, category, isAlcool } }
        });
      }
    }
  };

  const remove = () => {
    if (quantityLeft != null && !loading) {
      if (quantity > 0) {
        isLoading(true);
        socket().emit("unlock-item", itemID, () => {
          reduxDispatch({
            type: "[PURCHASE] remove",
            data: { item: { id: itemID, category } }
          });
          isLoading(false);
        });
      }
    } else {
      if (quantity > 0) {
        reduxDispatch({
          type: "[PURCHASE] remove",
          data: { item: { id: itemID, category } }
        });
      }
    }
  };

  return (
    <div className="quantity-wrapper">
      <MenuQuantityLeft quantityLeft={quantityLeft} />
      <IconButton
        edge="start"
        disabled={loading || quantity === 0 || shouldDisabledItem}
        onClick={remove}
      >
        <MinusIcon />
      </IconButton>
      <TextField
        type="number"
        value={quantity}
        inputProps={{
          pattern: "[0-9]",
          min: 0,
          max: 10,
          style: {
            textAlign: "center"
          }
        }}
        disabled
      />
      <IconButton
        edge="end"
        disabled={loading || shouldDisabledItem}
        onClick={add}
      >
        <PlusIcon />
      </IconButton>
    </div>
  );
};

export default MenuQuantity;
