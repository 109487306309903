import React, { useEffect } from "react";
import FormControl from "@material-ui/core/FormControl";
import { KeyboardDatePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import DateTime from "luxon/src/datetime.js";
import Subheader from "../UI/Subheader";
import LuxonUtils from "@date-io/luxon";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import socket from "../../../socket/socketIO";
import {
  getLitteralDate,
  orderDatesNumber,
  stringifyDate,
  getDateInterval
} from "../../../utils/orderDatesUtils";

const luxon = new LuxonUtils();

const FormDate = () => {
  const theme = useTheme();
  const reduxDispatch = useDispatch();

  const choices = useSelector(state => state.settings.pamPickupChoices.value);
  const isInPamMode = useSelector(state => state.settings.isInPamMode.value);
  const orderWithinSameDay = useSelector(
    state => state.settings.orderWithinSameDay.value
  );
  const noOrderCurrentDay = useSelector(
    state => state.settings.noOrderCurrentDay.value
  );
  const orderCurrentWeekOnly = useSelector(
    state => state.settings.orderCurrentWeekOnly.value
  );
  const delayToOrderInAdvanced = useSelector(
    state => state.settings.delayToOrderInAdvanced.value
  );
  let pickupDate = useSelector(state => state.purchase.orderDetails.date);
  const takeoutOpeningTime = useSelector(
    state => state.settings.takeoutOpeningTime
  );
  const deliveryOpeningTime = useSelector(
    state => state.settings.deliveryOpeningTime
  );
  const orderType = useSelector(
    state => state.purchase.orderDetails.type.value
  );
  const deliveryWhenPossible = useSelector(
    state => state.settings.deliveryWhenPossible.value
  );

  const openingDays = [];

  Object.values(
    orderType === "delivery" ? deliveryOpeningTime : takeoutOpeningTime
  ).forEach((e, i) => {
    if (!e.isClosed) {
      openingDays.push(i);
    }
  });

  useEffect(() => {
    if (pickupDate.value === "") {
      const nextDate = getNextAvailableDate();
      if (nextDate) {
        setPickupDate(nextDate.toISO());
      } else {
        setPickupDate(undefined, false);
      }
    }
    // eslint-disable-next-line
  }, [orderType]);

  useEffect(() => {
    if (isDateDisabled(luxon.date(pickupDate.value))) {
      const nextDate = getNextAvailableDate();
      if (nextDate) {
        setPickupDate(nextDate.toISO());
      } else {
        setPickupDate(undefined, false);
      }
    }
    if (pickupDate.value && !pickupDate.value.invalid) {
      socket().emit(
        "get-hours-availability",
        { date: pickupDate.value },
        data => {
          reduxDispatch({
            type: "[PURCHASE] set hours",
            data
          });
        }
      );
    }
    // eslint-disable-next-line
  }, [reduxDispatch, pickupDate.value]);

  const setPickupDate = (value, valid = true) => {
    reduxDispatch({
      type: "[PURCHASE] set details",
      data: { value, id: "date", valid }
    });
    if (!(deliveryWhenPossible && orderType === "delivery")) {
      reduxDispatch({
        type: "[PURCHASE] set details",
        data: { value: "", id: "hour", valid: undefined }
      });
    }
  };

  const handleDate = date => {
    setPickupDate(date);
  };

  if (isInPamMode) {
    const datesDropdown = orderDatesNumber(choices);
    return (
      <>
        <Subheader>Date de livraison</Subheader>
        <FormControl
          error={pickupDate.valid !== undefined && !pickupDate.valid}
          fullWidth
          variant="outlined"
          style={{ marginTop: 20 }}
        >
          <InputLabel>{"Date de livraison"}</InputLabel>
          <Select
            value={pickupDate.value}
            onChange={handleDate}
            label="Heure ramassage"
          >
            {datesDropdown.map(date => {
              const d = DateTime.fromISO(date);
              return (
                <MenuItem key={d.toISODate()} value={d.toISODate()}>
                  {stringifyDate(date)}
                </MenuItem>
              );
            })}
          </Select>
          {pickupDate.valid !== undefined && !pickupDate.valid && (
            <FormHelperText>Veuillez choisir une heure</FormHelperText>
          )}
        </FormControl>
      </>
    );
  }

  const isDateDisabled = date => {
    const today = DateTime.local();
    const weekday = date.weekday !== 7 ? date.weekday : 0;

    return (
      date < today.startOf("day") ||
      !openingDays.includes(weekday) ||
      date >= luxon.addDays(today, delayToOrderInAdvanced + 1).startOf("day") ||
      (orderCurrentWeekOnly && today.weekNumber !== date.weekNumber) ||
      (noOrderCurrentDay && today.toISODate() === date.toISODate())
    );
  };

  const getNextAvailableDate = () => {
    const nextDay = getDateInterval().find(d => {
      const wd = d.start.weekday === 7 ? 0 : d.start.weekday;
      if (!openingDays.includes(wd)) {
        return false;
      }
      return !isDateDisabled(d.start);
    });
    return nextDay ? nextDay.start : false;
  };

  if (!orderType) {
    return null;
  }

  if (!getNextAvailableDate()) {
    return (
      <>
        <Subheader>Date de la commande</Subheader>
        <div className="orderDate-container">Aucune date disponible</div>
      </>
    );
  }

  return (
    <>
      <Subheader>Date de la commande</Subheader>
      <div className="orderDate-container">
        <p>{`Votre commande sera ${getLitteralDate(pickupDate.value)}`}</p>
        {!orderWithinSameDay &&
          "Pour modifier la date, consultez le calendrier:"}
        <KeyboardDatePicker
          className="orderDate"
          variant="outlined"
          format="dd/MM/yyyy"
          disabled={orderWithinSameDay}
          disableToolbar={orderWithinSameDay}
          margin="normal"
          value={pickupDate.value}
          onChange={handleDate}
          shouldDisableDate={isDateDisabled}
          keyboardbuttonprops={{
            "aria-label": "changement de date"
          }}
        />
        {orderWithinSameDay ? (
          <p className="notice" style={{ color: theme.palette.primary.main }}>
            Toutes les commandes se font la journée même uniquement
          </p>
        ) : null}
      </div>
    </>
  );
};

export default FormDate;
