import React from "react";
import Button from "@material-ui/core/Button";

const PrimaryButton = ({ children, ...props }) => {
  return (
    <Button color="primary" variant="contained" size="large" {...props}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
