import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ViewHandler from "../ViewHandler";
import PrimaryButton from "../../../components/PrimaryButton";
import { useSelector } from "react-redux";
import FormOrderButton from "./FormOrderButton";
import ConfirmDialog from "../../../components/ConfirmDialog";
import PixelAction from "../../../Pixel/PixelAction";
import { getTotalWithTaxesAndTip, getSubtotal } from "../Utils/cart";
import Currency from "currency.js";

const BottomActions = () => {
  const reduxDispatch = useDispatch();
  const [open, setDialog] = useState(false);
  const [minimumCostDialog, setMinimumCostDialog] = useState(false);

  const minimumDeliveryCost = useSelector(
    state => state.settings.minimumDeliveryCost
  );
  const forceFoodWithAlcool = useSelector(
    state => state.settings.forceFoodWithAlcool
  );
  const totalCountWithAlcool = useSelector(
    state => state.purchase.totalCountWithAlcool
  );
  const totalCountWithNoAlcool = useSelector(
    state => state.purchase.totalCountWithNoAlcool
  );
  const hasDelivery = useSelector(state => state.settings.hasDelivery.value);
  const hasTakeout = useSelector(state => state.settings.hasTakeOut.value);

  const menu = useSelector(state => state.purchase.menu);
  const isMenuDisabled = ![].concat
    .apply([], Object.values(menu))
    .filter(item => item.quantity > 0).length;

  const { name, phone } = useSelector(state => state.purchase.infos);
  const isInfoDisabled = !(name.valid && phone.valid);

  const { type, date, hour } = useSelector(
    state => state.purchase.orderDetails
  );
  const isOrderDetails1Disabled = !(date.valid && type.valid);

  const address = useSelector(state => state.purchase.address);
  const orderType = useSelector(
    state => state.purchase.orderDetails.type.value
  );

  const isTipSet = useSelector(
    state => state.purchase.orderDetails.tip.value !== null
  );

  const isCheckoutDisabled = useSelector(
    state => !state.purchase.card.complete
  );

  const isOrderDetails2Disabled = !(
    (address.valid || orderType === "takeout") &&
    hour.valid
  );

  const currentView = useSelector(state => state.purchase.view);

  const views = [
    "menu",
    "info",
    "orderDetails-1",
    "orderDetails-2",
    "tipAndDonation",
    "checkout"
  ];

  const setView = view => {
    reduxDispatch({
      type: "[PURCHASE] set view",
      data: { view }
    });
  };

  const isAlcoolyNotOk = () => {
    return (
      !forceFoodWithAlcool.value &&
      totalCountWithAlcool > 0 &&
      !totalCountWithNoAlcool > 0
    );
  };

  const isMinimumCostNotOk = () => {
    const subtotal = getSubtotal();
    return subtotal.value < minimumDeliveryCost.value && hasDelivery;
  };

  const menuNext = () => {
    if (isAlcoolyNotOk()) {
      setDialog(true);
    } else if (isMinimumCostNotOk()) {
      setMinimumCostDialog(true);
    } else {
      next();
    }
  };

  const dialogConfirm = () => {
    setDialog(false);
    next();
  };

  const minimumCostDialogConfirm = () => {
    setMinimumCostDialog(false);
    next();
  };

  const next = () => {
    const index = views.findIndex(v => v === currentView);
    const nextIndex = views.length - 1 === index ? index : index + 1;
    setView(views[nextIndex]);
  };

  const prev = () => {
    const index = views.findIndex(v => v === currentView);
    const nextIndex = index === 0 ? index : index - 1;
    setView(views[nextIndex]);
  };

  return (
    <div className="button-container">
      <ConfirmDialog
        open={open}
        handleClose={() => setDialog(false)}
        callback={dialogConfirm}
        message={
          <>
            La livraison d'alcool sans nourriture n'est pas permise.
            <strong> Seulement</strong> la commande pour
            <strong> emporter </strong>
            sera disponobile.
          </>
        }
        title={"Attention"}
      />
      <ConfirmDialog
        open={minimumCostDialog}
        handleClose={() => setMinimumCostDialog(false)}
        callback={
          hasTakeout
            ? minimumCostDialogConfirm
            : () => setMinimumCostDialog(false)
        }
        message={
          <>
            {`Afin de passer une livraison, le sous-total minimum requis est de ${Currency(
              minimumDeliveryCost.value
            ).format(false)}$`}
          </>
        }
        accept={hasTakeout ? "J'irai chercher ma commande!" : "D'accord"}
        cancel={hasTakeout ? "Retourner au menu" : null}
        title={"Attention"}
      />
      <ViewHandler view="menu">
        <PixelAction event="AddToCart">
          <PrimaryButton disabled={isMenuDisabled} onClick={menuNext}>
            Suivant
          </PrimaryButton>
        </PixelAction>
      </ViewHandler>
      <ViewHandler view="info">
        <PrimaryButton onClick={prev}>Précédent</PrimaryButton>
        <PixelAction event="AddPaymentInfo">
          <PrimaryButton
            disabled={isInfoDisabled}
            onClick={() => setView("orderDetails-1")}
          >
            Suivant
          </PrimaryButton>
        </PixelAction>
      </ViewHandler>
      <ViewHandler view="orderDetails-1">
        <PrimaryButton onClick={prev}>Précédent</PrimaryButton>
        <PrimaryButton disabled={isOrderDetails1Disabled} onClick={next}>
          Suivant
        </PrimaryButton>
      </ViewHandler>
      <ViewHandler view="orderDetails-2">
        <PrimaryButton onClick={prev}>Précédent</PrimaryButton>
        <PrimaryButton disabled={isOrderDetails2Disabled} onClick={next}>
          Suivant
        </PrimaryButton>
      </ViewHandler>
      <ViewHandler view="tipAndDonation">
        <PrimaryButton onClick={prev}>Précédent</PrimaryButton>
        <PrimaryButton disabled={!isTipSet} onClick={next}>
          Suivant
        </PrimaryButton>
      </ViewHandler>
      <ViewHandler view="checkout">
        <PrimaryButton onClick={prev}>Précédent</PrimaryButton>
        <PixelAction
          event="Purchase"
          data={{ currency: "CAD", value: getTotalWithTaxesAndTip() }}
        >
          <FormOrderButton disabled={isCheckoutDisabled}>
            Commander
          </FormOrderButton>
        </PixelAction>
      </ViewHandler>
    </div>
  );
};

export default BottomActions;
