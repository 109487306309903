import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Orders from "./Orders";
import TabOrderWrapper from "./Tabs/TabOrderWrapper";
import LuxonUtils from "@date-io/luxon";
import TabPanel from "./Tabs/TabPanel";

const luxon = new LuxonUtils();

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fbfbfb"
  },
  rootPanel: {}
}));

export default function AllTakeOuts() {
  const classes = useStyles();
  const [date, setDate] = React.useState(luxon.date().startOf("day"));
  const [value, setValue] = React.useState(0);

  return (
    <TabOrderWrapper
      value={value}
      date={date}
      setDate={setDate}
      setValue={setValue}
      init="initTakeOutDates"
      onKey="newOrder"
    >
      <div className={classes.root}>
        <TabPanel classes={{ root: classes.rootPanel }} value={value} index={0}>
          <Orders
            notifType="orders"
            orderType="takeout"
            date={date}
            emit="initTakeOuts"
            init="initTakeOuts"
            archivedEmit="archiveOrder"
            noOrderMsg="Aucune commande"
            onKey="newOrder"
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Orders
            notifType="orders"
            orderType="takeout"
            date={date}
            emit="archivedTakeOuts"
            init="initTakeOuts"
            archivedEmit="unarchiveOrder"
            noOrderMsg="Aucune commande archivée"
          />
        </TabPanel>
      </div>
    </TabOrderWrapper>
  );
}
