import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Socket from "../../../../../socket/socketIOAdmin";
import LuxonUtils from "@date-io/luxon";
import { useEffect } from "react";
import Badge from "@material-ui/core/Badge";
import AppBar from "@material-ui/core/AppBar";
import DateTime from "luxon/src/datetime.js";
import TabPanel from "./TabPanel";
import TabsDatePicker from "./TabDatePicker";
import TabSelectADate from "./TabSelectADate";

const luxon = new LuxonUtils();

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    marginTop: 100,
    marginBottom: 100,
    backgroundColor: "initial"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: 175
  },
  panel: {
    flex: 1,
    paddingTop: 0,
    minHeight: "100vh"
  },
  iconLabelRoot: {
    minHeight: 50
  },
  iconLabelWrapper: {
    flexDirection: "row-reverse",
    justifyContent: "flex-end"
  },
  badge: {
    width: 15,
    marginBottom: "-1px !important"
  }
}));

export default function VerticalTabs({
  date,
  value: inPanelValue,
  setDate,
  setValue: setInPanelValue,
  init,
  children,
  onKey
}) {
  const classes = useStyles();
  const [customDialog, setCustomDialog] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [orderDates, setOrderDates] = React.useState(0);
  const today = DateTime.local().toISODate();

  const orderDatesKeys = Object.keys(orderDates);

  useEffect(() => {
    Socket().emit(init, {}, response => {
      if (!Object.keys(response).includes(today)) {
        setOrderDates({ [today]: 0, ...response });
      } else {
        setOrderDates(response);
      }
    });
    Socket().on(onKey, data => {
      const newOrderDate = DateTime.fromISO(data.pickupDate).toISODate();
      setOrderDates(orderDates => {
        if (orderDates[newOrderDate]) {
          return {
            ...orderDates,
            [newOrderDate]: orderDates[newOrderDate] + 1
          };
        } else {
          return {
            ...orderDates,
            [newOrderDate]: 1
          };
        }
      });
    });
    return () => Socket().off(onKey);
  }, []);
  console.log(orderDates);
  const handleChange = (event, newValue) => {
    if (orderDatesKeys.length !== newValue) {
      const panelDate = DateTime.fromISO(Object.keys(orderDates)[newValue]);
      setDate(luxon.date(panelDate.toJSDate()));
    }
    setValue(newValue);
  };

  const handleCustomDateChange = date => {
    setDate(date);
    setCustomDialog(false);
  };

  const handleInPanelChange = (event, newValue) => {
    setInPanelValue(newValue);
  };

  const formatTabDate = date => {
    if (today === date) {
      return "aujourd'hui";
    }
    return date;
  };

  const customPanelSelected = () => {
    setDate(null);
  };

  if (!orderDatesKeys.length) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          {orderDatesKeys.map((d, i) => {
            return (
              <Tab
                key={d}
                label={formatTabDate(d)}
                icon={
                  <Badge
                    classes={{ root: classes.badge }}
                    badgeContent={orderDates[d]}
                    color="primary"
                  />
                }
                classes={{
                  root: classes.iconLabelRoot,
                  wrapper: classes.iconLabelWrapper
                }}
                {...a11yProps(i)}
              />
            );
          })}
          <Tab
            key="manuel"
            label="manuel"
            onClick={customPanelSelected}
            classes={{
              root: classes.iconLabelRoot,
              wrapper: classes.iconLabelWrapper
            }}
            {...a11yProps(orderDatesKeys.length)}
          />
        </Tabs>
        {orderDatesKeys.map((d, i) => {
          return (
            <TabPanel
              key={`TabPanel-${d}`}
              className={classes.panel}
              value={value}
              index={i}
            >
              <AppBar position="static" color="default">
                <Tabs
                  value={inPanelValue}
                  onChange={handleInPanelChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="À traiter" {...a11yProps(0)} />
                  <Tab label="Archivées" {...a11yProps(1)} />
                </Tabs>
                <TabsDatePicker
                  setOpen={setCustomDialog}
                  open={customDialog}
                  value={date}
                  onChange={handleCustomDateChange}
                  disabled
                />
              </AppBar>
              {children}
            </TabPanel>
          );
        })}
        <TabPanel
          key={`TabPanel-manuel`}
          className={classes.panel}
          value={value}
          index={orderDatesKeys.length}
        >
          <AppBar position="static" color="default">
            <Tabs
              value={inPanelValue}
              onChange={handleInPanelChange}
              aria-label="simple tabs example"
            >
              <Tab label="À traiter" {...a11yProps(0)} />
              <Tab label="Archivées" {...a11yProps(1)} />
            </Tabs>
            <TabsDatePicker
              setOpen={setCustomDialog}
              open={customDialog}
              onChange={handleCustomDateChange}
              value={date}
            />
          </AppBar>
          {date === null ? (
            <TabSelectADate
              value={inPanelValue}
              onClick={() => setCustomDialog(true)}
            />
          ) : (
            <>{children}</>
          )}
        </TabPanel>
      </div>
    </>
  );
}
