import React from "react";
import Subheader from "../../UI/Subheader";

const FormAddressFeeConfirmation = ({ distance }) => {
  return (
    <>
      <Subheader>Frais de livraison</Subheader>
      <div>
        <p style={{ textAlign: "center", marginTop: 40 }}>
          <i>
            {`Vous êtes environ à `}
            <strong>{`${distance.text}`}</strong> {` du commerce.`}
            <br />
            {`Malheureusement, nous ne livrons pas à cette distance.`}
          </i>
        </p>
      </div>
    </>
  );
};

export default FormAddressFeeConfirmation;
