import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

const PamRoute = ({ children, path }) => {
  const isInPamMode = useSelector(state => state.settings.isInPamMode.value);

  if (!isInPamMode) {
    return (
      <Route path={path}>
        <Suspense fallback={<></>}>{children}</Suspense>
      </Route>
    );
  } else {
    return null;
  }
};
export default PamRoute;
