import React, { useReducer, useEffect } from "react";
import socket from "../../../socket/socketIOAdmin";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import { ListSubheader } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import User from "./User";

const Users = () => {
  const [state, dispatch] = useReducer(reducer, []);
  useEffect(() => {
    socket().emit("get-users", {}, response => {
      const { users } = response;
      dispatch({ type: "initUsers", data: users });
    });
  }, []);

  return (
    <Grid
      container
      direction="row"
      justify="center"
      style={{ marginBottom: 100 }}
    >
      <Grid
        item
        xs={12}
        md={8}
        style={{ marginTop: 100, marginBottom: 40, maxWidth: "100%" }}
      >
        <div className="menu-container">
          <List>
            <ListSubheader
              style={{ backgroundColor: "white", color: "#6e6e6e" }}
            >
              <Typography
                variant="h6"
                style={{
                  marginTop: 20,
                  paddingBottom: 10,
                  paddingTop: 10,
                  color: "black",
                  borderBottom: "1px #252525 solid",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                Utilisateurs
                <div className="action-bar">
                  <Tooltip title="Ajouter un item" aria-label="Ajouter un item">
                    <IconButton
                      edge="end"
                      onClick={() => {
                        socket().emit(
                          "create-user",
                          {
                            role: "employe",
                            email: `usager-${state.length + 1}`
                          },
                          user => {
                            dispatch({
                              type: "createNewUser",
                              user: user[0]
                            });
                          }
                        );
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Typography>
            </ListSubheader>
            {state.map(user => {
              return (
                <User
                  deleteUser={() => {
                    socket().emit(
                      "delete-user",
                      {
                        id: user.id
                      },
                      () => {
                        dispatch({
                          type: "deleteUser",
                          id: user.id
                        });
                      }
                    );
                  }}
                  editUser={(toBeEdited, cb) => {
                    socket().emit(
                      "edit-user",
                      {
                        id: user.id,
                        ...toBeEdited
                      },
                      editedUser => {
                        cb();
                        dispatch({
                          type: "editUser",
                          user: editedUser
                        });
                      }
                    );
                  }}
                  key={user.id}
                  user={user}
                />
              );
            })}
          </List>
        </div>
      </Grid>
    </Grid>
  );
};

export default Users;

const reducer = (state, action) => {
  switch (action.type) {
    case "initUsers":
      return action.data;
    case "createNewUser":
      return [...state, action.user];
    case "deleteUser":
      return state.filter(user => user.id !== action.id);
    case "editUser":
      return state.map(user => {
        if (user.id === action.user.id) {
          return action.user;
        } else {
          return user;
        }
      });
    default:
      return state;
  }
};
