import store from "../../../Store/RootReducer";
import DateTime from "luxon/src/datetime.js";
import Currency from "currency.js";
import LuxonUtils from "@date-io/luxon";
const luxon = new LuxonUtils();

const isPast = time => {
  const t = Currency(time).divide(100);
  const getHour = () => {
    return t.dollars();
  };
  const getMinutes = () => {
    return 60 * (t.cents() / 100);
  };

  const timeToCompare = DateTime.fromObject({
    hours: getHour(),
    minutes: getMinutes()
  });

  return timeToCompare < DateTime.local();
};

const isBefore = time => {
  const t = Currency(time).divide(100);
  const getHour = () => {
    return t.dollars();
  };
  const getMinutes = () => {
    return 60 * (t.cents() / 100);
  };

  const timeToCompare = DateTime.fromObject({
    hours: getHour(),
    minutes: getMinutes()
  });
  return timeToCompare > DateTime.local();
};
const isToday = () => {
  const {
    purchase: {
      orderDetails: {
        date: { value }
      }
    }
  } = store.getState();
  const today = luxon.date();
  const selectedDate = luxon.date(value);
  return today.toISODate() === selectedDate.toISODate();
};
const isTypeClosed = openingTime => {
  const {
    purchase: {
      orderDetails: {
        date: { value }
      }
    }
  } = store.getState();
  let day = DateTime.fromISO(value).weekday;
  day = day === 7 ? 0 : day;

  const isCurrentTypeClosed = openingTime[day] && !!openingTime[day].isClosed;
  const isOutOfOpenItme = openingTime[day] && isPast(openingTime[day].close);

  if (isCurrentTypeClosed || (isOutOfOpenItme && isToday())) {
    return true;
  }

  return false;
};

export const isDeliveryClosed = () => {
  const {
    settings: { deliveryOpeningTime }
  } = store.getState();
  return isTypeClosed(deliveryOpeningTime);
};

export const isTakeoutClosed = () => {
  const {
    settings: { takeoutOpeningTime }
  } = store.getState();

  return isTypeClosed(takeoutOpeningTime);
};

export const isTodayClosed = () => {
  const {
    settings: { openingHour }
  } = store.getState();
  let today = DateTime.local().weekday;
  today = today === 7 ? 0 : today;
  if (openingHour[today].isClosed) {
    return true;
  }
  return isPast(openingHour[today].close) || isBefore(openingHour[today].open);
};

export default () => {
  // if (process.env.REACT_APP_FORCE_ENV !== "production") {
  //   return false;
  // }

  if (isTodayClosed()) {
    return true;
  }

  const {
    settings: { orderWithinSameDay }
  } = store.getState();

  const isTwoTypeClosed = isTakeoutClosed() && isDeliveryClosed();

  if (isTwoTypeClosed && orderWithinSameDay.value) {
    return true;
  }

  return false;
};
