import socket from "../../socket/socketIO";

const initialState = {
  view: "menu",
  menu: {},
  categories: [],
  totalCountWithNoAlcool: 0,
  totalCountWithAlcool: 0,
  infos: {
    name: { value: "", valid: undefined },
    phone: { value: "", valid: undefined }
  },
  orderDetails: {
    date: { value: "", valid: undefined },
    type: { value: "", valid: undefined },
    hour: { value: "", valid: undefined },
    tip: { value: undefined },
    note: { value: "", valid: true }
  },
  address: {
    street: { value: "", valid: undefined },
    city: { value: "", valid: undefined },
    state: { value: "Québec", valid: true },
    zip: { value: "", valid: undefined },
    valid: false
  },
  options: {
    distance: { value: 0, text: "", valid: undefined },
    duration: { value: 0, text: "", valid: undefined },
    discount: { value: undefined, valid: undefined },
    takeoutFee: undefined,
    deliveryFee: undefined,
    donation: undefined,
    status: undefined
  },
  hours: {
    hoursAvailbality: {},
    deliveryHoursAvailbality: {}
  },
  card: {
    error: undefined,
    complete: false
  },
  lock: false,
  confirmedOrderID: null,
  hasLimitedQuantityItem: false
};

const validateInfos = (id, value) => {
  const isEmpty = !value.length;

  switch (id) {
    case "phone":
      return value.length === 14;
    case "name":
      return value.length > 2;
    case "note":
      return true;
    default:
      return !isEmpty;
  }
};

const validateAddress = (id, value) => {
  const zipRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

  switch (id) {
    case "street":
      return value.length > 3;
    case "city":
      return !!value.length;
    case "zip":
      return RegExp(zipRegex).test(value);
    default:
      return !!value.length;
  }
};

const validateAllAddress = address => {
  let areInfosValid = true;

  Object.keys(address).forEach(key => {
    const data = address[key];
    if (data.valid === undefined || !data.valid) {
      areInfosValid = false;
    }
  });

  return areInfosValid;
};

const updateQuantityLeft = (state, action) => {
  const menu = { ...state.menu };
  const categories = state.categories.reduce((obj, category) => {
    obj[category.id] = category.title;
    return obj;
  }, {});

  action.data.items.forEach(it => {
    const categoryTitle = categories[it.category];
    menu[categoryTitle] = menu[categoryTitle].map(mItem => {
      if (mItem.id === it.id) {
        return { ...mItem, ...it };
      }
      return mItem;
    });
  });

  return { ...state, menu };
};

const updateQuantity = (obj, menu, quantityAdjustement) => {
  return {
    menu: {
      ...menu,
      [obj.category]: [
        ...menu[obj.category].reduce((arr, item) => {
          if (item.id === obj.id) {
            arr.push({
              ...item,
              quantity: item.quantity + quantityAdjustement
            });
          } else {
            arr.push(item);
          }
          return arr;
        }, [])
      ]
    }
  };
};

const addItem = (state, action) => {
  const { menu } = updateQuantity(action.data.item, state.menu, 1);
  return {
    ...state,
    totalCountWithNoAlcool: !action.data.item.isAlcool
      ? state.totalCountWithNoAlcool + 1
      : state.totalCountWithNoAlcool,
    totalCountWithAlcool: action.data.item.isAlcool
      ? state.totalCountWithAlcool + 1
      : state.totalCountWithAlcool,
    menu,
    hasLimitedQuantityItem: !![].concat
      .apply([], Object.values(menu))
      .find(m => m.quantityLeft !== null && m.quantity > 0)
  };
};

const emptyCart = menu => {
  const categories = Object.keys(menu);
  const newMenu = {};
  categories.forEach(c => {
    newMenu[c] = menu[c].map(e => ({ ...e, quantity: 0 }));
  });
  socket().emit("free-items");
  return {
    menu: newMenu
  };
};

const removeItem = (state, action) => {
  const { menu } = updateQuantity(action.data.item, state.menu, -1);
  const newCountWithoutAlcool = !action.data.item.isAlcool
    ? state.totalCountWithNoAlcool - 1
    : state.totalCountWithNoAlcool;

  const newCountWithAlcool = action.data.item.isAlcool
    ? state.totalCountWithAlcool + 1
    : state.totalCountWithAlcool;

  if (newCountWithoutAlcool <= 0) {
    return {
      ...state,
      ...emptyCart(state.menu),
      totalCountWithNoAlcool: newCountWithoutAlcool,
      totalCountWithAlcool: newCountWithAlcool
    };
  }
  return {
    ...state,
    menu,
    totalCountWithNoAlcool: newCountWithoutAlcool,
    totalCountWithAlcool: newCountWithAlcool,
    hasLimitedQuantityItem: !![].concat
      .apply([], Object.values(menu))
      .find(m => m.quantityLeft !== null && m.quantity > 0)
  };
};

const setInfos = (state, action) => {
  return {
    ...state,
    infos: {
      ...state.infos,
      [action.data.id]: {
        value: action.data.value,
        valid: validateInfos(action.data.id, action.data.value)
      }
    }
  };
};

const setOrderDetails = (state, action) => {
  const options = { ...state.options };
  if (action.data.value === "takeout") {
    delete options.isPam;
    delete options.isDelivery;
  }
  return {
    ...state,
    options,
    orderDetails: {
      ...state.orderDetails,
      [action.data.id]: {
        value: action.data.value,
        valid: action.data.valid
      }
    }
  };
};

const setAddress = (state, action) => {
  const newAddress = {
    ...state.address,
    [action.data.id]: {
      value: action.data.value,
      valid: validateAddress(action.data.id, action.data.value)
    }
  };
  delete newAddress.valid;
  return {
    ...state,
    address: {
      ...newAddress,
      valid: validateAllAddress(newAddress)
    }
  };
};

const resetAddress = (state, action) => {
  return {
    ...state,
    address: initialState.address,
    options: {
      ...state.options,
      distance: initialState.options.distance,
      duration: initialState.options.duration,
      status: initialState.options.status
    }
  };
};

const setOptions = (state, action) => {
  return {
    ...state,
    options: {
      ...state.options,
      ...action.data
    }
  };
};

const setHours = (state, action) => {
  return {
    ...state,
    hours: {
      ...state.hours,
      ...action.data
    }
  };
};

const setCard = (state, action) => {
  return {
    ...state,
    card: {
      ...state.card,
      ...action.data
    }
  };
};

const PurchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "[PURCHASE] set confirmedOrderID":
      return { ...state, confirmedOrderID: action.data.confirmedOrderID };
    case "[PURCHASE] set card":
      return setCard(state, action);
    case "[PURCHASE] set hours":
      return setHours(state, action);
    case "[PURCHASE] set options":
      return setOptions(state, action);
    case "[PURCHASE] set address":
      return setAddress(state, action);
    case "[PURCHASE] reset address":
      return resetAddress(state, action);
    case "[PURCHASE] set lock":
      return { ...state, lock: action.data.value };
    case "[PURCHASE] set details":
      return setOrderDetails(state, action);
    case "[PURCHASE] set infos":
      return setInfos(state, action);
    case "[PURCHASE] set view":
      return { ...state, view: action.data.view };
    case "[PURCHASE] set menu":
      return { ...state, menu: action.data.menu };
    case "[PURCHASE] set categories":
      return { ...state, categories: action.data.categories };
    case "[PURCHASE] add":
      return addItem(state, action);
    case "[PURCHASE] remove":
      return removeItem(state, action);
    case "[PURCHASE] set quantity left":
      return updateQuantityLeft(state, action);
    default:
      return state;
  }
};

export default PurchaseReducer;
