import React from "react";
import Grid from "@material-ui/core/Grid";
import OrdersSalesStats from "./OrdersSalesStats";
import { KeyboardDatePicker } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
const luxon = new LuxonUtils();

const Dashboard = () => {
  const [fromDate, setFromDate] = React.useState(luxon.date().startOf("day"));
  const [toDate, setToDate] = React.useState(luxon.date().startOf("day"));
  const handleDate = date => {
    if (date > toDate) {
      setFromDate(date);
      setToDate(date);
    } else {
      setFromDate(date);
    }
  };

  return (
    <>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id="from"
        label="Du"
        value={fromDate}
        onChange={handleDate}
        keyboardbuttonprops={{
          "aria-label": "change date"
        }}
      />
      <KeyboardDatePicker
        style={{ marginLeft: 30 }}
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id="to"
        label="Jusqu'au"
        value={toDate}
        shouldDisableDate={date => date < fromDate}
        onChange={date => setToDate(date)}
        keyboardbuttonprops={{
          "aria-label": "change date"
        }}
      />
      <div className="dashboard-container">
        <Grid container spacing={10}>
          <OrdersSalesStats fromDate={fromDate} toDate={toDate} />
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
