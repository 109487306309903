import React from "react";
import Subheader from "../../UI/Subheader";

const FormAddressMinimumFee = ({ minimum, distance }) => {
  return (
    <>
      <Subheader>Frais de livraison</Subheader>
      <div>
        <p style={{ textAlign: "center", marginTop: 40 }}>
          <i>
            {`Vous êtes environ à `}
            <strong>{`${distance.text}`}</strong> {` du commerce.`}
            <br />
            {`Le prix minimum pour cette distance est de `}
            <strong>{`${minimum}$`}</strong>
          </i>
        </p>
      </div>
    </>
  );
};

export default FormAddressMinimumFee;
