import React, { useReducer, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Socket from "../../../../socket/socketIOAdmin";
import convertTime from "../../../../utils/convertTime";
import reducer from "./Archive-Reducer";
import Order from "../Order/Order";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import LuxonUtils from "@date-io/luxon";
const luxon = new LuxonUtils();

const Orders = ({
  date,
  emit,
  init,
  archivedEmit,
  noOrderMsg,
  onKey,
  orderType,
  notifType
}) => {
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, null);
  const [categories, setCategories] = useState(null);
  const counts = useSelector(state => state.notif.counts);

  useEffect(() => {
    Socket().emit(emit, { date: date.toISODate() }, response => {
      dispatch({ type: init, data: response });

      if (date.toISODate() === luxon.date().toISODate() && onKey) {
        Socket().on(onKey, data => {
          if (
            luxon.date(data.pickupDate).toISODate() === luxon.date().toISODate()
          ) {
            dispatch({ type: onKey, order: data });
          }
        });
      }
    });
    return () => Socket().off(onKey);
  }, [date, emit, onKey, init]);

  useEffect(() => {
    const resetSpecificNotif = () => {
      if (counts[notifType] > 0) {
        reduxDispatch({
          type: "reset-specific-notif",
          data: { orderType: notifType }
        });
      }
    };

    document.body.addEventListener("click", resetSpecificNotif, true);
    return () => {
      document.body.removeEventListener("click", resetSpecificNotif, true);
    };
  }, [counts, notifType, reduxDispatch]);

  useEffect(() => {
    Socket().emit("get-categories", {}, response => {
      const { categories } = response;
      setCategories(categories);
    });
  }, [reduxDispatch]);

  const handleArchived = (id, key) => {
    Socket().emit(archivedEmit, { id }, () => {
      dispatch({ type: "archiveOrder", data: { id, key } });
    });
  };

  if (state === null) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress style={{ width: 22, height: 22 }} />
      </div>
    );
  }

  const orders = Object.keys(state);

  return (
    <>
      {!orders.length ? (
        <div style={{ paddingTop: 24 }}>{noOrderMsg}</div>
      ) : (
        Object.keys(state).map(time => (
          <div key={time} className="time-container">
            <h1 className="picked-time">
              {isNaN(time) ? time : convertTime(time)}
            </h1>
            <hr />
            <Grid container>
              {state[time].map(order => (
                <Order
                  key={order.id}
                  order={order}
                  isArchived={archivedEmit.includes("unarchive")}
                  categories={categories}
                  handleArchived={() => handleArchived(order.id, time)}
                  orderType={orderType}
                />
              ))}
            </Grid>
          </div>
        ))
      )}
    </>
  );
};

export default Orders;
