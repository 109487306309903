import React from "react";
import ListItem from "@material-ui/core/ListItem";
import MenuQuantity from "./MenuQuantity";
import MenuItemDescription from "./MenuItemDescription";

const MenuItem = ({ item, category }) => {
  const horsStock = item.quantityLeft === 0 && item.quantity === 0;
  return (
    <ListItem
      style={{
        flexWrap: "wrap",
        pointerEvents: horsStock ? "none" : "",
        padding: "8px 0"
      }}
    >
      <MenuItemDescription
        description={item.description}
        subdescription={item.subdescription}
        price={item.price}
        horsStock={horsStock}
      />
      <MenuQuantity
        itemID={item.id}
        quantity={item.quantity}
        quantityLeft={item.quantityLeft}
        category={category}
        isAlcool={item.isAlcool}
      />
    </ListItem>
  );
};
export default React.memo(MenuItem, (prevProps, nextProps) => {
  return (
    prevProps.item.quantityLeft === nextProps.item.quantityLeft &&
    prevProps.item.quantity === nextProps.item.quantity
  );
});
