import React from "react";
import FormAddressDynamic from "./FormAddressDynamic";
import FormAddressFeeConfirmation from "./FormAddressFeeConfirmation";
import FormAddressMinimumFee from "./FormAddressMinimumFee";
import FormAddressOutOfRange from "./FormAddressOutOfRange";
import getDeliveryFee from "../../../../utils/getDeliveryFee";
import { getSubtotal } from "../../Utils/cart";
import { useSelector } from "react-redux";

const FADHandler = () => {
  const distance = useSelector(state => state.purchase.options.distance);
  const status = useSelector(state => state.purchase.options.status);
  const deliveryFee = getDeliveryFee(
    getSubtotal().dollars(),
    distance ? distance.value : 0
  );

  if (status === "out_of_range") {
    return <FormAddressOutOfRange distance={distance} />;
  } else if (String(status).includes("min_")) {
    return (
      <FormAddressMinimumFee
        minimum={String(status).split("min_")[1]}
        distance={distance}
      />
    );
  } else if (distance.valid) {
    return (
      <FormAddressFeeConfirmation
        deliveryFee={deliveryFee}
        distance={distance}
      />
    );
  } else {
    return <FormAddressDynamic />;
  }
};
export default FADHandler;
