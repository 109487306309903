import React from "react";
import Typography from "@material-ui/core/Typography";

const Subheader = ({ children, style }) => {
  return (
    <Typography
      variant="h6"
      style={{
        paddingBottom: 10,
        paddingTop: 10,
        color: "black",
        borderBottom: "1px #252525 solid",
        ...style
      }}
    >
      {children}
    </Typography>
  );
};

export default Subheader;
