import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useSelector, useDispatch } from "react-redux";
import { days } from "../../../utils/orderDatesUtils";
import convertTime, {
  convertTimeNumber,
  round
} from "../../../utils/convertTime";
import TextField from "@material-ui/core/TextField";
import { updateSetting } from "../../../Store/Actions/Setting-Actions";
import Typography from "@material-ui/core/Typography";
import { ListSubheader } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import TimerOffIcon from "@material-ui/icons/TimerOff";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Switch from "@material-ui/core/Switch";
import { toast } from "react-toastify";
import { useTheme } from "@material-ui/core/styles";

const Settings = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ marginTop: 100 }}
    >
      <HourSection />
      <GeneralSection />
    </Grid>
  );
};
const AdminSettings = ({ children }) => {
  const { email } = useSelector(state => state.auth.user.user);
  if (email === "intek") {
    return <>{children}</>;
  } else {
    return <></>;
  }
};
const HourSection = () => {
  const {
    takeoutOpeningTime,
    deliveryOpeningTime,
    openingHour,
    hasTakeOut,
    hasDelivery
  } = useSelector(state => state.settings);
  const takeoutOpeningTimeKeys = Object.keys(takeoutOpeningTime);
  const deliveryOpeningTimeKeys = Object.keys(deliveryOpeningTime);
  const openingTimeKeys = Object.keys(openingHour);
  return (
    <>
      <Grid style={{ padding: 50 }} item xs={12}>
        <div className="menu-container settings">
          <List>
            <ListSubheader
              style={{ backgroundColor: "white", color: "#6e6e6e" }}
            >
              <Typography
                variant="h6"
                style={{
                  paddingBottom: 10,
                  color: "black",
                  borderBottom: "1px #252525 solid",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                {"Heure d'ouverture de l'application"}
              </Typography>
            </ListSubheader>
            {openingTimeKeys.map(key => (
              <HourLine
                key={key}
                section="openingHour"
                id={key}
                time={openingHour}
              />
            ))}
          </List>
        </div>
      </Grid>
      {hasTakeOut.value && (
        <Grid style={{ padding: 50 }} item xs={12}>
          <div className="menu-container settings">
            <List>
              <ListSubheader
                style={{ backgroundColor: "white", color: "#6e6e6e" }}
              >
                <Typography
                  variant="h6"
                  style={{
                    paddingBottom: 10,
                    color: "black",
                    borderBottom: "1px #252525 solid",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  {"Heure de ramassage"}
                </Typography>
              </ListSubheader>
              {takeoutOpeningTimeKeys.map(key => (
                <HourLine
                  key={key}
                  section="takeoutOpeningTime"
                  id={key}
                  time={takeoutOpeningTime}
                />
              ))}
            </List>
          </div>
        </Grid>
      )}
      {hasDelivery.value && (
        <Grid style={{ padding: 50 }} item xs={12}>
          <div className="menu-container settings">
            <List>
              <ListSubheader
                style={{ backgroundColor: "white", color: "#6e6e6e" }}
              >
                <Typography
                  variant="h6"
                  style={{
                    paddingBottom: 10,
                    color: "black",
                    borderBottom: "1px #252525 solid",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  {"Heure de livraison"}
                </Typography>
              </ListSubheader>
              {deliveryOpeningTimeKeys.map(key => (
                <HourLine
                  key={key}
                  section="deliveryOpeningTime"
                  id={key}
                  time={deliveryOpeningTime}
                />
              ))}
            </List>
          </div>
        </Grid>
      )}
    </>
  );
};

const GeneralSection = () => {
  const {
    companyPicture,
    stripeKey,
    hasTakeOut,
    hasDelivery,
    orderWithinSameDay,
    timeStep,
    delayMinimalBeforeTakeout,
    delayMinimalBeforeDelivery,
    maxOrderPerStepTakeout,
    maxOrderPerStepDelivery,
    deliveryFee,
    minimumPriceForFreeDelivery,
    delayToOrderInAdvanced,
    takeoutFee,
    minimumPriceForFreeTakeout,
    deliveryWhenPossible,
    forceFoodWithAlcool,
    restaurantID,
    location,
    restaurantPath,
    restaurantName,
    noOrderCurrentDay,
    orderCurrentWeekOnly,
    facebookPixelID,
    googleMapPath,
    minimumDeliveryCost
  } = useSelector(state => state.settings);
  return (
    <Grid style={{ padding: 50 }} item xs={12}>
      <div className="menu-container settings">
        <List>
          <ListSubheader style={{ backgroundColor: "white", color: "#6e6e6e" }}>
            <Typography
              variant="h6"
              style={{
                paddingBottom: 10,
                color: "black",
                borderBottom: "1px #252525 solid",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              {"Paramètres généraux"}
            </Typography>
          </ListSubheader>

          <CompanyLogo companyPicture={companyPicture} />
          <AdminSettings>
            <StripeComponent stripeKey={stripeKey} />
            <TextComponent
              textValue={facebookPixelID}
              textKey="facebookPixelID"
              title={`Facebook Pixel ID`}
              subTitle="Le ID fournie par Facebook pour pouvoir track les usagers"
            />
            <TextComponent
              textValue={restaurantID}
              textKey="restaurantID"
              title={`ID du restaurant`}
              subTitle="Numéro d'identifiant unique utilisé par Pizzli comme référence"
            />
            <TextComponent
              textValue={restaurantPath}
              textKey="restaurantPath"
              title={`URL Client `}
              subTitle="Lien de votre page de vente à utiliser sur la carte Pizzli"
            />
            <LocationComponent location={location} />
            <TextComponent
              textValue={googleMapPath}
              textKey="googleMapPath"
              title={`Lien de l'emplacement sur Google map`}
              subTitle="Faire un copy paste du lien trouvé sur Google maps, sera utilisé pour les SMS"
            />
          </AdminSettings>
          <TextComponent
            textValue={restaurantName}
            textKey="restaurantName"
            title={`Nom du restaurant`}
            subTitle="Nom à utiliser sur la carte Pizzli"
          />
          <ToggleComponent
            toggleValue={forceFoodWithAlcool}
            toggleKey="forceFoodWithAlcool"
            title="Forcer l'achat de nourriture avec l'alcool"
            subTitle="Sur une catégorie de menu identifiée comme de l'alcool, l'achat de nourriture non-alcoolique est obligatoire"
          />
          <ToggleComponent
            toggleValue={orderWithinSameDay}
            toggleKey="orderWithinSameDay"
            title="Commande la journée courante seulement"
            subTitle="Si active, ce paramètre bloque la possibilité d'achat aux dates ultérieures"
          />
          <StepComponent timeStep={timeStep} />
          <NumberComponent
            disabled={orderWithinSameDay.value}
            numberValue={delayToOrderInAdvanced}
            numberKey="delayToOrderInAdvanced"
            title="Nombre de jours maximum de commande"
            subTitle="Maximum du nombre de jour jusqu'auquel un client peut commander"
          />
          <ToggleComponent
            toggleValue={noOrderCurrentDay}
            toggleKey="noOrderCurrentDay"
            title="Aucune commande le jour courant"
            subTitle="Les emportés et les livraisons ne pourront pas être commander le jour courant"
          />
          <ToggleComponent
            toggleValue={orderCurrentWeekOnly}
            toggleKey="orderCurrentWeekOnly"
            title="Forcer la semaine courante"
            subTitle="Seulement les dates de la semaine courante seront disponible aux commandes"
          />

          <ToggleComponent
            toggleValue={hasTakeOut}
            toggleKey="hasTakeOut"
            title="Activer les emportés"
            subTitle="Si actif, les clients pourront sélectionner le type de commande Emporté"
          />
          {hasTakeOut.value && (
            <>
              <NumberComponent
                numberValue={delayMinimalBeforeTakeout}
                numberKey="delayMinimalBeforeTakeout"
                title="Délai minimal pour l'heure de ramassage"
                subTitle="Délai autoriser entre l'heure de commande du client et la première heure disponible au ramassage"
              />
              <NumberComponent
                numberValue={maxOrderPerStepTakeout}
                numberKey="maxOrderPerStepTakeout"
                title={`Nombre maximal de commande`}
                subTitle={`Nombre maximal de commande pour emporter par bond de ${timeStep.value *
                  (60 / 100)} minutes`}
              />
              <NumberComponent
                numberValue={takeoutFee}
                numberKey="takeoutFee"
                title={`Frais pour emporter`}
                subTitle="Frais facturé un client pour une commande à emporter"
              />
              <NumberComponent
                disabled={takeoutFee.value <= 0}
                numberValue={minimumPriceForFreeTakeout}
                numberKey="minimumPriceForFreeTakeout"
                title={`Montant minimal`}
                subTitle="Le montant minimal afin de bénéficer de l'exemption du frais à emporter"
              />
            </>
          )}
          <ToggleComponent
            toggleValue={hasDelivery}
            toggleKey="hasDelivery"
            title="Activer la livraison"
            subTitle="Si actif, les clients pourront sélectionner le type de commande Livraison"
          />

          {hasDelivery.value && (
            <>
              <NumberComponent
                numberValue={delayMinimalBeforeDelivery}
                numberKey="delayMinimalBeforeDelivery"
                title="Délai minimal pour l'heure de livraison"
                subTitle="Délai autoriser entre l'heure de commande du client et la première heure disponible à la livraison"
              />
              <NumberComponent
                numberValue={maxOrderPerStepDelivery}
                numberKey="maxOrderPerStepDelivery"
                title={`Nombre maximal de livraison`}
                subTitle={`Nombre maximal de livraison par bond de ${timeStep.value *
                  (60 / 100)} minutes`}
              />
              <ToggleComponent
                toggleValue={deliveryWhenPossible}
                toggleKey="deliveryWhenPossible"
                title="Livraison sans heure fixe"
                subTitle="Les clients de pourront pas choisir d'heure lors de la commande, premier arrivé, premier servi."
              />
              <NumberComponent
                numberValue={minimumDeliveryCost}
                numberKey="minimumDeliveryCost"
                title={`Montant minimal pour faire une livraison`}
                subTitle="Le montant que le client doit commander pour être en mesure de faire une livraison."
              />
              <NumberComponent
                numberValue={deliveryFee}
                numberKey="deliveryFee"
                title={`Frais de livraison`}
                subTitle="Frais facturé un client pour une livraison"
              />

              <NumberComponent
                disabled={deliveryFee.value <= 0}
                numberValue={minimumPriceForFreeDelivery}
                numberKey="minimumPriceForFreeDelivery"
                title={`Montant minimal`}
                subTitle="Le montant minimal afin de bénéficer de l'exemption du frais de livraison"
              />
            </>
          )}
        </List>
      </div>
    </Grid>
  );
};

const CompanyLogo = ({ companyPicture }) => {
  const theme = useTheme();
  const [isEdit, setEdit] = useState(false);
  const [path, setPath] = useState(companyPicture.path);
  const dispatch = useDispatch();
  const handleChange = cb => {
    updateSetting(
      "companyPicture",
      {
        path
      },
      dispatch,
      cb
    );
  };
  if (!isEdit) {
    return (
      <ListItem>
        <ListItemText
          style={{ color: theme.palette.primary.main }}
          className="description"
          primary={"Logo"}
          secondary="L'image apparaitra dans le haut du menu et sur la carte Pizzli"
        />
        <div style={{ height: "48px", flex: "1" }}>
          <img style={{ height: " 100%" }} src={path} alt="company logo" />
        </div>
        <Tooltip title="Modifier" aria-label="Modifier">
          <IconButton onClick={() => setEdit(true)} edge="end">
            <EditIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  } else {
    return (
      <ListItem>
        <ListItemText
          style={{ color: theme.palette.primary.main }}
          className="description"
          primary={"Logo"}
          secondary="L'image apparaitra dans le haut du menu et sur la carte Pizzli"
        />
        <TextField
          style={{ flex: "1", margin: "0 10px" }}
          value={path}
          onChange={e => setPath(e.target.value)}
          label="url de l'image"
        />
        <Tooltip title="Sauvegarder" aria-label="Sauvegarder">
          <IconButton onClick={() => handleChange(setEdit(false))} edge="end">
            <DoneIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  }
};
const LocationComponent = ({ location }) => {
  const theme = useTheme();
  const [isEdit, setEdit] = useState(false);
  const [lon, setLon] = useState(location.value[0]);
  const [lat, setLat] = useState(location.value[1]);
  const dispatch = useDispatch();
  const handleChange = cb => {
    updateSetting(
      "location",
      {
        value: [lon, lat]
      },
      dispatch,
      cb
    );
  };
  if (!isEdit) {
    return (
      <ListItem>
        <ListItemText
          style={{ color: theme.palette.primary.main }}
          className="description"
          primary={"Location"}
          secondary="Coordonné GPS à utiliser sur la carte Pizzli"
        />
        <div style={{ flex: "1" }}>{lon + ", " + lat}</div>
        <Tooltip title="Modifier" aria-label="Modifier">
          <IconButton onClick={() => setEdit(true)} edge="end">
            <EditIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  } else {
    return (
      <ListItem>
        <ListItemText
          style={{ color: theme.palette.primary.main }}
          className="description"
          primary={"Location"}
          secondary="Coordonné GPS à utiliser sur la carte Pizzli"
        />
        <TextField
          style={{ flex: "1", margin: "0 10px" }}
          value={lon}
          type="number"
          onChange={e => setLon(Number(e.target.value))}
          label="Lon"
        />
        <TextField
          style={{ flex: "1", margin: "0 10px" }}
          value={lat}
          type="number"
          onChange={e => setLat(Number(e.target.value))}
          label="Lat"
        />
        <Tooltip title="Sauvegarder" aria-label="Sauvegarder">
          <IconButton onClick={() => handleChange(setEdit(false))} edge="end">
            <DoneIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  }
};
const StripeComponent = ({ stripeKey }) => {
  const theme = useTheme();
  const [isEdit, setEdit] = useState(false);
  const [key, setKey] = useState(stripeKey.value);
  const dispatch = useDispatch();
  const handleChange = cb => {
    updateSetting(
      "stripeKey",
      {
        value: key
      },
      dispatch,
      cb
    );
  };
  if (!isEdit) {
    return (
      <ListItem>
        <ListItemText
          style={{ color: theme.palette.primary.main }}
          className="description"
          primary={"Clé Stripe"}
          secondary="Numéro nécessaire au système de paiement"
        />
        <div style={{ flex: "1" }}>{key}</div>
        <Tooltip title="Modifier" aria-label="Modifier">
          <IconButton onClick={() => setEdit(true)} edge="end">
            <EditIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  } else {
    return (
      <ListItem>
        <ListItemText
          style={{ color: theme.palette.primary.main }}
          className="description"
          primary={"Clé Stripe"}
          secondary="Numéro nécessaire au système de paiement"
        />
        <TextField
          style={{ flex: "1", margin: "0 10px" }}
          value={key}
          onChange={e => setKey(e.target.value)}
          label="Clé stripe"
        />
        <Tooltip title="Sauvegarder" aria-label="Sauvegarder">
          <IconButton onClick={() => handleChange(setEdit(false))} edge="end">
            <DoneIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  }
};
const StepComponent = ({ timeStep }) => {
  const theme = useTheme();
  const [isEdit, setEdit] = useState(false);
  const [step, setStep] = useState(timeStep.value * (60 / 100));
  const dispatch = useDispatch();
  const handleChange = cb => {
    const roundValue = round(Number((step / (60 / 100)).toFixed(0)));

    if (Number((step / (60 / 100)).toFixed(0)) !== roundValue) {
      toast.error("La valeur doit être un multiple de 15.");
    }

    updateSetting(
      "timeStep",
      {
        value: roundValue
      },
      dispatch,
      cb
    );
  };

  if (!isEdit) {
    return (
      <ListItem>
        <ListItemText
          style={{ color: theme.palette.primary.main }}
          className="description"
          primary={"Temps entre les commandes"}
          secondary="Bond entre chaque heure disponible lors de la commande du client"
        />
        <div className="center" style={{ flex: "1" }}>
          {step}
        </div>
        <Tooltip title="Modifier" aria-label="Modifier">
          <IconButton onClick={() => setEdit(true)} edge="end">
            <EditIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  } else {
    return (
      <ListItem>
        <ListItemText
          style={{ color: theme.palette.primary.main }}
          className="description"
          primary={"Temps entre les commandes"}
          secondary="Bond entre chaque heure disponible lors de la commande du client"
        />
        <TextField
          className="center"
          style={{ flex: "1", margin: "0 10px" }}
          value={step}
          onChange={e => setStep(e.target.value)}
          type="number"
          label="Nombre de minutes"
        />
        <Tooltip title="Sauvegarder" aria-label="Sauvegarder">
          <IconButton onClick={() => handleChange(setEdit(false))} edge="end">
            <DoneIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  }
};
const TextComponent = ({ textValue, textKey, title, subTitle }) => {
  const theme = useTheme();
  const [isEdit, setEdit] = useState(false);
  const [text, settext] = useState(textValue.value);
  const dispatch = useDispatch();
  const handleChange = cb => {
    updateSetting(
      textKey,
      {
        value: text
      },
      dispatch,
      cb
    );
  };
  if (!isEdit) {
    return (
      <ListItem>
        <ListItemText
          style={{ color: theme.palette.primary.main }}
          className="description"
          primary={title}
          secondary={subTitle}
        />
        <div style={{ flex: "1" }}>{text}</div>
        <Tooltip title="Modifier" aria-label="Modifier">
          <IconButton onClick={() => setEdit(true)} edge="end">
            <EditIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  } else {
    return (
      <ListItem>
        <ListItemText
          style={{ color: theme.palette.primary.main }}
          className="description"
          primary={title}
          secondary={subTitle}
        />
        <TextField
          style={{ flex: "1", margin: "0 10px" }}
          value={text}
          onChange={e => settext(e.target.value)}
          label="Valeur"
        />
        <Tooltip title="Sauvegarder" aria-label="Sauvegarder">
          <IconButton onClick={() => handleChange(setEdit(false))} edge="end">
            <DoneIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  }
};
const NumberComponent = ({
  numberValue,
  numberKey,
  title,
  subTitle,
  disabled
}) => {
  const theme = useTheme();
  const [isEdit, setEdit] = useState(false);
  const [number, setnumber] = useState(numberValue.value);
  const dispatch = useDispatch();
  const handleChange = cb => {
    updateSetting(
      numberKey,
      {
        value: Number(number)
      },
      dispatch,
      cb
    );
  };
  if (!isEdit) {
    return (
      <ListItem>
        <ListItemText
          style={{ color: theme.palette.primary.main }}
          className="description"
          primary={title}
          secondary={subTitle}
        />
        <div className="center" style={{ flex: "1" }}>
          {disabled ? "N/D" : number}
        </div>
        <Tooltip title="Modifier" aria-label="Modifier">
          <IconButton
            disabled={disabled}
            onClick={() => setEdit(true)}
            edge="end"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  } else {
    return (
      <ListItem>
        <ListItemText
          style={{ color: theme.palette.primary.main }}
          className="description"
          primary={title}
          secondary={subTitle}
        />
        <TextField
          className="center"
          style={{ flex: "1", margin: "0 10px" }}
          value={number}
          onChange={e => setnumber(e.target.value)}
          type="number"
          label="Quantité"
        />
        <Tooltip title="Sauvegarder" aria-label="Sauvegarder">
          <IconButton onClick={() => handleChange(setEdit(false))} edge="end">
            <DoneIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  }
};
const ToggleComponent = ({ toggleValue, toggleKey, title, subTitle }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const handleChange = () => {
    updateSetting(toggleKey, { value: !toggleValue.value }, dispatch);
  };

  return (
    <ListItem>
      <ListItemText
        style={{ color: theme.palette.primary.main }}
        className="description"
        primary={title}
        secondary={subTitle}
      />
      <div className="switch-wrapper">
        <Switch
          checked={toggleValue.value}
          onChange={handleChange}
          color="primary"
          name="checkedB"
          inputProps={{
            "aria-label": "primary checkbox"
          }}
        />
      </div>
    </ListItem>
  );
};
const HourLine = ({ id, time, section }) => {
  const { open, close, isClosed } = time[id];
  const [openTime, setOpen] = useState(convertTime(open, ":", true));
  const [closeTime, setClose] = useState(convertTime(close, ":", true));
  const [isEdit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (cb, toggle = false) => {
    updateSetting(
      section,
      {
        ...time,
        [id]: {
          open: convertTimeNumber(openTime),
          close: convertTimeNumber(closeTime),
          isClosed: toggle ? !isClosed : isClosed
        }
      },
      dispatch,
      cb
    );
  };
  if (!isEdit) {
    return (
      <ListItem>
        <ListItemText
          primary={days[id]}
          style={{
            width: 325,
            flex: "initial"
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flex: "1",
            textDecoration: isClosed ? "line-through" : ""
          }}
        >
          <div>{openTime}</div>
          <div>{closeTime}</div>
        </div>
        <Tooltip title="Ouverture" aria-label="Ouverture">
          <IconButton edge="start" onClick={() => handleChange(null, true)}>
            {isClosed ? <QueryBuilderIcon /> : <TimerOffIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Modifier" aria-label="Modifier">
          <IconButton onClick={() => setEdit(true)} edge="end">
            <EditIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  } else {
    return (
      <ListItem>
        <ListItemText
          primary={days[id]}
          style={{
            flex: 1,
            minWidth: 150
          }}
        />
        <TextField
          id="time"
          style={{ flex: "1", margin: "0 10px" }}
          value={openTime}
          onChange={e => setOpen(e.target.value)}
          label="Heure ouverture"
          type="time"
          inputProps={{
            step: 300 // 5 min
          }}
        />
        <TextField
          id="time"
          value={closeTime}
          onChange={e => setClose(e.target.value)}
          style={{ flex: "1", margin: "0 10px" }}
          label="Heure fermeture"
          type="time"
          inputProps={{
            step: 300 // 5 min
          }}
        />
        <Tooltip title="Ouverture" aria-label="Ouverture">
          <IconButton onClick={() => handleChange(null, true)} edge="start">
            {isClosed ? <QueryBuilderIcon /> : <TimerOffIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Sauvegarder" aria-label="Sauvegarder">
          <IconButton onClick={() => handleChange(setEdit(false))} edge="end">
            <DoneIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  }
};

export default Settings;
