import React from "react";

const QuantityLeft = ({ quantityLeft }) => {
  if (quantityLeft === null) {
    return null;
  }
  return (
    <span style={{ padding: "0 20px", fontWeight: 300 }}>
      {quantityLeft} en stock
    </span>
  );
};

export default QuantityLeft;
