import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

const Stat = ({ icon, color = "yellow", title, subHeader, footer }) => {
  return (
    <Grid item xs={4}>
      <div className="stat-wrapper">
        <Card className="stat">
          <CardHeader
            className="header"
            avatar={<Box className={`box ${color}`}>{icon}</Box>}
            title={<div className="title">{title}</div>}
            subheader={<div className="data">{subHeader}</div>}
          />
          <CardActions>
            <div className="footer">{footer}</div>
          </CardActions>
        </Card>
      </div>
    </Grid>
  );
};

export default Stat;
