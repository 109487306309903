import store from "../Store/RootReducer";
import DateTime from "luxon/src/datetime.js";
import Interval from "luxon/src/interval.js";

export const days = [
  "dimanche",
  "lundi",
  "mardi",
  "mercredi",
  "jeudi",
  "vendredi",
  "samedi"
];

export const months = [
  "janvier",
  "février",
  "mars",
  "avril",
  "mai",
  "juin",
  "juillet",
  "aout",
  "septembre",
  "octobre",
  "novembre",
  "décembre"
];

export const getLitteralDate = date => {
  if (!date) {
    return "";
  }

  const d = DateTime.fromISO(date);
  const dayNo = d.weekday === 7 ? 0 : d.weekday;
  const monthNo = d.month - 1;

  return `${days[dayNo]} le ${d.day} ${months[monthNo]}`;
};

export const orderDatesNumber = daysNo => {
  const today = DateTime.local();
  const todayNo = today.getDay();

  const orderedDates = daysNo.reduce((arr, dNo) => {
    let dayNo = dNo;
    if (dNo === 0) {
      dayNo = 7;
    }

    const d = DateTime.local();
    if (todayNo >= dayNo) {
      d.setDate(d.getDate() + ((7 - d.getDay()) % 7) + dayNo);
      arr.push(d.toISOString());
    } else {
      d.setDate(d.getDate() + ((((7 - d.getDay()) % 7) + dayNo) % 7));
      arr.unshift(d.toISOString());
    }

    return arr;
  }, []);

  return orderedDates;
};

export const stringifyDate = d => {
  let string = "";
  const thisWeek = DateTime.local().weekNumber;
  const date = DateTime.fromISO(d);
  const dateWeek = date.weekNumber;
  let weekday = date.weekday;
  if (weekday === 7) {
    weekday = 0;
  }

  if (thisWeek === dateWeek) {
    string = `${date.toLocaleString()} - ${days[weekday]}`;
  } else {
    string = `${date.toLocaleString()} - ${days[weekday]} suivant`;
  }

  return string;
};

export const getDateInterval = () => {
  const {
    settings: { orderCurrentWeekOnly, noOrderCurrentDay }
  } = store.getState();
  let startDate = DateTime.local();
  let endDate = DateTime.local().plus({ month: 1 });

  if (orderCurrentWeekOnly.value) {
    endDate = endDate.endOf("week");
  }

  if (noOrderCurrentDay.value) {
    startDate = startDate.plus({ days: 1 });
  }

  return Interval.fromDateTimes(
    startDate.startOf("day"),
    endDate.endOf("day")
  ).splitBy({ days: 1 });
};
