import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

let timer = null;
const ActivityDialog = ({ open, handleClose }) => {
  useEffect(() => {
    if (open) {
      timer = setTimeout(() => {
        window.location.reload();
      }, 30000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        Êtes-vous toujours là ?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Oui
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActivityDialog;
