import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { ListSubheader } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import socket from "../../../socket/socketIOAdmin";
import ListItem from "@material-ui/core/ListItem";
import HeightIcon from "@material-ui/icons/Height";
import DragHandleIcon from "@material-ui/icons/DragHandle";
const MenuSection = ({
  category,
  handleCreate,
  categories,
  handleDeleteCategory,
  isAlcool,
  handleDrag,
  expanded,
  provided,
  description
}) => {
  const [isEdit, setEdit] = useState(false);
  const [title, setTitle] = useState(category);
  const [alcool, setAlcool] = useState(isAlcool);
  const [Description, setDescription] = useState(description);
  if (isEdit) {
    return (
      <ListSubheader
        className="menusection-wrapper"
        style={{ backgroundColor: "white", color: "#6e6e6e" }}
      >
        <Typography
          variant="h6"
          style={{
            marginTop: 20,
            paddingBottom: 10,
            paddingTop: 10,
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {!expanded ? (
              <Tooltip title="Déplacer" aria-label="Déplacer">
                <div {...provided.dragHandleProps}>
                  <DragHandleIcon
                    onClick={() => handleDrag(true)}
                    style={{ marginRight: 20 }}
                  />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title="Changer l'ordre" aria-label="Changer l'ordre">
                <IconButton edge="start" onClick={() => handleDrag(false)}>
                  <HeightIcon />
                </IconButton>
              </Tooltip>
            )}
            <TextField
              style={{ flex: 1, minWidth: 150 }}
              value={title}
              onChange={e => setTitle(e.target.value)}
              label="Categorie"
            />
          </div>
          <div className="action-bar">
            <Tooltip title="Sauvegarder" aria-label="Sauvegarder">
              <IconButton
                onClick={() => {
                  socket().emit(
                    "update-category",
                    {
                      id: categories[category],
                      title,
                      description: Description
                    },
                    updatedCategory => {
                      setEdit(false);
                    }
                  );
                }}
              >
                <DoneIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Effacer une catégorie"
              aria-label="Effacer une catégorie"
            >
              <IconButton onClick={handleDeleteCategory}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ajouter un item" aria-label="Ajouter un item">
              <IconButton edge="end" onClick={handleCreate}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Typography>
        <TextField
          style={{ flex: 1, width: "100%" }}
          value={Description}
          onChange={e => setDescription(e.target.value)}
          label="Description"
        />
      </ListSubheader>
    );
  } else {
    return (
      <ListSubheader style={{ backgroundColor: "white", color: "#6e6e6e" }}>
        <Typography
          variant="h6"
          style={{
            marginTop: 20,
            paddingBottom: 10,
            paddingTop: 10,
            color: "black",
            borderBottom: "1px #252525 solid",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {!expanded ? (
              <Tooltip title="Déplacer" aria-label="Déplacer">
                <div {...provided.dragHandleProps} >
                  <DragHandleIcon
                    onClick={() => handleDrag(true)}
                    style={{ marginRight: 20 }}
                  />
                </div>
              </Tooltip>
            ) : (
              <Tooltip title="Changer l'ordre" aria-label="Changer l'ordre">
                <IconButton edge="start" onClick={() => handleDrag(false)}>
                  <HeightIcon />
                </IconButton>
              </Tooltip>
            )}
            {title}
          </div>
          <div className="action-bar" style={{ minWidth: 180 }}>
            <Tooltip title="Type de section" aria-label="Category Alcool ?">
              <IconButton
                onClick={() => {
                  socket().emit(
                    "update-category",
                    { id: categories[category], isAlcool: !alcool },
                    updatedCategory => {
                      setAlcool(updatedCategory[0].isAlcool);
                    }
                  );
                }}
              >
                {alcool ? <LocalBarIcon /> : <FastfoodIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Modifier" aria-label="Modifier">
              <IconButton onClick={() => setEdit(true)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Effacer une catégorie"
              aria-label="Effacer une catégorie"
            >
              <IconButton onClick={handleDeleteCategory}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ajouter un item" aria-label="Ajouter un item">
              <IconButton edge="end" onClick={handleCreate}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Typography>
      </ListSubheader>
    );
  }
};

export default MenuSection;
