import React, { useEffect, useState } from "react";
import Socket from "../../../socket/socketIOAdmin";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ListSubheader } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import formatOrderDate from "../../../utils/formatOrderDate";

const WeekReport = ({ isPam }) => {
  const [orderList, setOrderList] = useState([]);
  useEffect(() => {
    if (isPam) {
      Socket().emit("get-weekPamCount", {}, response => {
        setOrderList(response);
      });
    } else {
      Socket().emit("get-weekOrderCount", {}, response => {
        setOrderList(response);
      });
    }
  }, [isPam]);

  if (!Object.keys(orderList).length) {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        style={{ marginBottom: 100 }}
      >
        <Grid
          item
          xs={12}
          md={8}
          style={{ marginTop: 100, marginBottom: 40, maxWidth: "100%" }}
        >
          <div className="menu-container">Aucune donnée</div>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      style={{ marginBottom: 100 }}
    >
      <Grid
        item
        xs={12}
        md={8}
        style={{ marginTop: 100, marginBottom: 40, maxWidth: "100%" }}
      >
        <div className="menu-container">
          {Object.keys(orderList).map(date => {
            return (
              <>
                <ListSubheader
                  style={{ backgroundColor: "white", color: "#6e6e6e" }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      paddingBottom: 10,
                      paddingTop: 10,
                      color: "black",
                      borderBottom: "1px #252525 solid"
                    }}
                  >
                    {formatOrderDate(date)}
                  </Typography>
                </ListSubheader>
                {orderList[date].map(item => {
                  return (
                    <ListItem style={{ flexWrap: "wrap" }}>
                      <ListItemText
                        primary={<>{item.description} </>}
                        style={{
                          flex: 1,
                          minWidth: 150
                        }}
                        primaryTypographyProps={{
                          style: { fontWeight: 300 }
                        }}
                      />
                      <div className="quantity-wrapper">{item.quantity}</div>
                    </ListItem>
                  );
                })}
              </>
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
};

export default WeekReport;
