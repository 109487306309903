import socketIOAdmin from "../../socket/socketIOAdmin";

export const updateSetting = (updateKey, updateValue, dispatch, cb) => {
  socketIOAdmin().emit(
    "update-settings",
    { key: updateKey, value: updateValue },
    resp => {
      const { key, value } = resp[0];
      if (key && value) {
        if (dispatch) {
          dispatch({ type: "update-settings", key, value });
        }
        if (cb) {
          cb();
        }
      }
    }
  );
};
