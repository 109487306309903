import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { updateSetting } from "../../../Store/Actions/Setting-Actions";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";

import { useTheme } from "@material-ui/core/styles";

const Description = ({ setting, settingKey, keyDescription }) => {
  const theme = useTheme();
  const [isEdit, setEdit] = useState(false);
  const [value, setValue] = useState(setting.value);
  const dispatch = useDispatch();
  const handleChange = cb => {
    updateSetting(
      settingKey,
      {
        value
      },
      null,
      cb
    );
  };
  if (!isEdit) {
    return (
      <ListItem>
        <div style={{ flex: "1" }}>{value ? value : keyDescription}</div>
        <Tooltip title="Modifier" aria-label="Modifier">
          <IconButton onClick={() => setEdit(true)} edge="end">
            <EditIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  } else {
    return (
      <ListItem>
        <TextField
          style={{ flex: "1", margin: "0 10px" }}
          value={value}
          onChange={e => setValue(e.target.value)}
          label={keyDescription}
        />
        <Tooltip title="Sauvegarder" aria-label="Sauvegarder">
          <IconButton onClick={() => handleChange(setEdit(false))} edge="end">
            <DoneIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  }
};

export default Description;
