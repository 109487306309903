import React from "react";
import Subheader from "../UI/Subheader";
import ChoiceBox from "../../../components/ChoiceBox";
import Currency from "currency.js";
import DeliveryIcon from "@material-ui/icons/LocalShipping";
import TakeOutIcon from "@material-ui/icons/LocalMall";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useSelector, useDispatch, batch } from "react-redux";
import { useEffect } from "react";
import getDeliveryFee from "../../../utils/getDeliveryFee";
import getTakeoutFee from "../../../utils/getTakeoutFee";
import { getSubtotal } from "../Utils/cart";
import DateTime from "luxon/src/datetime.js";

const FormOrderType = () => {
  const reduxDispatch = useDispatch();
  const deliveryFee = useSelector(state => state.settings.deliveryFee.value);

  const takeoutFee = useSelector(state => state.settings.takeoutFee.value);
  const minimumPriceForFreeDelivery = useSelector(
    state => state.settings.minimumPriceForFreeDelivery.value
  );
  const distance = useSelector(state => state.purchase.options.distance.value);
  const orderType = useSelector(state => state.purchase.orderDetails.type);
  const isInPamMode = useSelector(state => state.settings.isInPamMode.value);
  const minimumPriceForFreeTakeout = useSelector(
    state => state.settings.minimumPriceForFreeTakeout.value
  );
  const hasDelivery = useSelector(state => state.settings.hasDelivery.value);
  const hasTakeOut = useSelector(state => state.settings.hasTakeOut.value);
  const hasDynamicDeliveryFee = useSelector(
    state => state.settings.hasDynamicDeliveryFee.value
  );
  const deliveryWhenPossible = useSelector(
    state => state.settings.deliveryWhenPossible.value
  );
  const forceFoodWithAlcool = useSelector(
    state => state.settings.forceFoodWithAlcool
  );
  const totalCountWithAlcool = useSelector(
    state => state.purchase.totalCountWithAlcool
  );
  const totalCountWithNoAlcool = useSelector(
    state => state.purchase.totalCountWithNoAlcool
  );
  const takeoutOpeningTime = useSelector(
    state => state.settings.takeoutOpeningTime
  );
  const deliveryOpeningTime = useSelector(
    state => state.settings.deliveryOpeningTime
  );
  const orderWithinSameDay = useSelector(
    state => state.settings.orderWithinSameDay.value
  );
  const minimumDeliveryCost = useSelector(
    state => state.settings.minimumDeliveryCost.value
  );

  const today = DateTime.local();
  const subtotal = getSubtotal().dollars();

  const getNextOpeningTime = openingTime => {
    const nextWeekDay = Object.keys(openingTime).find(od => {
      const wd = od === 0 ? 7 : od;
      return wd >= today.weekday;
    });

    let dateToSelect = DateTime.fromObject({
      weekday: nextWeekDay === 0 ? 7 : nextWeekDay
    });

    if (dateToSelect.day < today.day) {
      dateToSelect = dateToSelect.plus({ days: 7 });
    }

    return dateToSelect.weekday;
  };

  const nextTakeoutOpeningTime = () => {
    return getNextOpeningTime(takeoutOpeningTime);
  };

  const nextdeliveryOpeningTime = () => {
    return getNextOpeningTime(deliveryOpeningTime);
  };

  const nextTakeoutDay = nextTakeoutOpeningTime();
  const nextDeliDay = nextdeliveryOpeningTime();

  const isTakeOutClose =
    (orderWithinSameDay && nextTakeoutDay > today.weekday) || !hasTakeOut;

  const isDeliveryClose =
    (orderWithinSameDay && nextDeliDay > today.weekday) ||
    !hasDelivery ||
    (!forceFoodWithAlcool.value &&
      totalCountWithAlcool > 0 &&
      !totalCountWithNoAlcool > 0);

  const setDefaultOrderType = () => {
    if (hasDelivery && !hasTakeOut && !isDeliveryClose) {
      reduxDispatch({
        type: "[PURCHASE] set details",
        data: { value: "delivery", id: "type", valid: true }
      });
    } else if (!hasDelivery && hasTakeOut && !isTakeOutClose) {
      reduxDispatch({
        type: "[PURCHASE] set details",
        data: { value: "takeout", id: "type", valid: true }
      });
    }
  };

  const setDeliverFee = () => {
    if (hasDynamicDeliveryFee) {
      reduxDispatch({
        type: "[PURCHASE] set options",
        data: { deliveryFee: undefined, takeoutFee: undefined }
      });
    } else {
      const deliveryFee = getDeliveryFee(subtotal, distance);
      reduxDispatch({
        type: "[PURCHASE] set options",
        data: { deliveryFee, takeoutFee: undefined }
      });
    }
  };

  const setTakeOutFee = () => {
    const takeoutFee = getTakeoutFee(subtotal);
    reduxDispatch({
      type: "[PURCHASE] set options",
      data: { takeoutFee, deliveryFee: undefined }
    });
  };

  useEffect(() => {
    batch(() => {
      setDefaultOrderType();
      if (orderType.value === "delivery") {
        setDeliverFee();
      } else if (!!minimumPriceForFreeTakeout) {
        setTakeOutFee();
      }
    });
    // eslint-disable-next-line
  }, [orderType.value]);

  const setInfos = (value, type) => {
    batch(() => {
      reduxDispatch({
        type: "[PURCHASE] set details",
        data: { value, id: "type", valid: true }
      });
      if (deliveryWhenPossible && type === "delivery") {
        reduxDispatch({
          type: "[PURCHASE] set details",
          data: {
            value: null,
            id: "hour",
            valid: true
          }
        });
      } else {
        reduxDispatch({
          type: "[PURCHASE] set details",
          data: {
            value: "",
            id: "hour",
            valid: undefined
          }
        });
      }

      reduxDispatch({
        type: "[PURCHASE] reset address"
      });
      if (value === "delivery") {
        const data = isInPamMode ? { isPam: true } : { isDelivery: true };
        reduxDispatch({
          type: "[PURCHASE] set options",
          data
        });
      }
    });
  };

  const SubTitle = () => {
    if (!hasDynamicDeliveryFee && !!deliveryFee) {
      return <small>{`(+${Currency(deliveryFee).format(false)}$)`}</small>;
    }
    return null;
  };

  const SubTitleTakeout = () => {
    if (!!takeoutFee) {
      return <small>{`(+${Currency(takeoutFee).format(false)}$)`}</small>;
    }
    return null;
  };

  const Delivery = () => {
    if (isDeliveryClose || subtotal < minimumDeliveryCost) {
      return (
        <ChoiceBox
          id="delivery"
          selected={orderType.value === "delivery"}
          title="Livraison"
          subTitle={<SubTitle />}
          img={<DeliveryIcon className="image" />}
          disabled
        />
      );
    }

    return (
      <ChoiceBox
        id="delivery"
        selected={orderType.value === "delivery"}
        title="Livraison"
        subTitle={<SubTitle />}
        img={<DeliveryIcon className="image" />}
        onClick={e => setInfos(e, "delivery")}
      />
    );
  };

  const TakeOut = () => {
    if (isTakeOutClose) {
      return (
        <ChoiceBox
          id="takeout"
          selected={orderType.value === "takeout"}
          title="Ramassage"
          subTitle={<SubTitleTakeout />}
          img={<TakeOutIcon className="image" />}
          disabled
        />
      );
    }

    return (
      <ChoiceBox
        id="takeout"
        selected={orderType.value === "takeout"}
        title="Ramassage"
        subTitle={<SubTitleTakeout />}
        img={<TakeOutIcon className="image" />}
        onClick={e => setInfos(e, "takeout")}
      />
    );
  };

  let subMsg = "";

  if (!!deliveryFee && orderType.value === "delivery") {
    subMsg = `Livraison gratuite pour les commandes de ${minimumPriceForFreeDelivery}$ et plus`;
  } else if (!!takeoutFee && orderType.value === "takeout") {
    subMsg = `Aucun frais pour les commandes de ${minimumPriceForFreeTakeout}$ et plus`;
  } else if (subtotal < minimumDeliveryCost && hasDelivery) {
    subMsg = `Afin de passer une livraison, le sous-total minimum requis est de ${Currency(
      minimumDeliveryCost
    ).format(false)}$`;
  }

  return (
    <>
      <Subheader>Type</Subheader>
      <div className="delivery-question">
        <Delivery />
        <TakeOut />
      </div>
      <FormHelperText
        style={{ width: "100%", textAlign: "center", minHeight: 19 }}
      >
        {subMsg}
      </FormHelperText>
    </>
  );
};

export default FormOrderType;
