import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminLayout from "../pages/Admin/Layout/AdminLayout";
import OrdersNotifications from "../pages/Admin/Orders/OrdersNotifications";
import Login from "../pages/Login/Login";

const SecureRoute = ({ path }) => {
  const { isAuthenticated } = useSelector(state => state.auth);

  if (isAuthenticated) {
    return (
      <Route path={path}>
        <OrdersNotifications />
        <div className="height-handler">
          <AdminLayout />
        </div>
      </Route>
    );
  } else {
    return (
      <Route path={path}>
        <Suspense fallback={<></>}>
          <Login />
        </Suspense>
      </Route>
    );
  }
};
export default SecureRoute;
