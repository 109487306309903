import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ConfirmDialog = ({
  callback,
  message,
  title,
  handleClose,
  open,
  cancel = "Annuler",
  accept = "Accepter"
}) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancel && (
          <Button onClick={handleClose} color="secondary">
            {cancel}
          </Button>
        )}
        <Button onClick={callback} color="primary">
          {accept}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
