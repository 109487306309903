import React from "react";
import { useSelector } from "react-redux";

const AdminOnly = ({ children }) => {
  const { role } = useSelector(state => state.auth.user.user);

  if (role === "admin") {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default AdminOnly;
