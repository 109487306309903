import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import DoneIcon from "@material-ui/icons/Done";
import TextField from "@material-ui/core/TextField";
import socket from "../../../socket/socketIOAdmin";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmDialog from "../../../components/ConfirmDialog";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import HelpIcon from "@material-ui/icons/Help";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import { useSelector } from "react-redux";

const MenuItem = ({ item, onSave, Delete, provided }) => {
  const noTaxesOnCertainItems = useSelector(
    state => state.settings.noTaxesOnCertainItems.value
  );
  const [isEdit, setEdit] = useState(item.isEdit);
  const [description, setDescription] = useState(item.description);
  const [subdescription, setSubdescription] = useState(item.subdescription);
  const [quantityLeft, setQuantity] = useState(item.quantityLeft);
  const [open, setDialog] = useState(false);
  const [price, setPrice] = useState((item.price / 100).toFixed(2));

  if (isEdit) {
    return (
      <>
        <ListItem style={{ flexWrap: "wrap" }}>
          <Tooltip title="Déplacer" aria-label="Déplacer">
            <div {...provided.dragHandleProps}>
              <DragHandleIcon style={{ marginRight: 20 }} />
            </div>
          </Tooltip>
          <TextField
            style={{ flex: 1, minWidth: 150 }}
            value={description}
            onChange={e => setDescription(e.target.value)}
            label="Nom"
          />
          <TextField
            style={{ flex: 1, maxWidth: 100, margin: "0 20px" }}
            value={price}
            onChange={e => setPrice(e.target.value)}
            type="number"
            label="Price"
          />
          <div className={`quantity-wrapper edit`} style={{ minWidth: 0 }}>
            <Tooltip title="Sauvegarder" aria-label="Sauvegarder">
              <IconButton
                edge="end"
                onClick={() => {
                  socket().emit(
                    "update-item",
                    {
                      ...item,
                      description,
                      price: (price * 100).toFixed(),
                      subdescription,
                      quantityLeft: quantityLeft === "" ? null : quantityLeft
                    },
                    updatedItem => {
                      setEdit(false);
                      onSave({
                        ...item,
                        ...updatedItem[0]
                      });
                    }
                  );
                }}
              >
                <DoneIcon />
              </IconButton>
            </Tooltip>
          </div>
        </ListItem>
        <ListItem style={{ flexWrap: "wrap", padding: "0 70px 0 60px" }}>
          <TextField
            style={{ flex: 1, minWidth: 150 }}
            value={subdescription}
            onChange={e => setSubdescription(e.target.value)}
            label="Description"
          />
          <TextField
            style={{ flex: 1, maxWidth: 100, marginLeft: 20 }}
            value={quantityLeft}
            onChange={e => setQuantity(e.target.value)}
            type="number"
            label="Quantité"
          />
        </ListItem>
      </>
    );
  } else {
    return (
      <>
        <ListItem style={{ flexWrap: "wrap" }}>
          <Tooltip title="Déplacer" aria-label="Déplacer">
            <div {...provided.dragHandleProps}>
              <DragHandleIcon style={{ marginRight: 20 }} />
            </div>
          </Tooltip>
          <ListItemText
            primary={`${item.description} - ${item.price / 100}`}
            style={{
              flex: 1,
              minWidth: 150,
              textDecoration: item.isAvailable ? "" : "line-through"
            }}
          />
          <div className={`quantity-wrapper`}>
            {subdescription ? (
              <Tooltip title={subdescription} aria-label={subdescription}>
                <IconButton edge="start">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            ) : null}
            {noTaxesOnCertainItems && (
              <Tooltip
                title={item.taxable ? "Taxable" : "Non-taxable"}
                aria-label={item.taxable ? "Taxable" : "Non-taxable"}
              >
                <IconButton
                  onClick={() => {
                    socket().emit(
                      "update-item",
                      { ...item, taxable: !item.taxable },
                      updatedItem => {
                        onSave({
                          ...item,
                          ...updatedItem[0]
                        });
                      }
                    );
                  }}
                >
                  {item.taxable ? <AttachMoneyIcon /> : <MoneyOffIcon />}
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Disponibilité" aria-label="Disponibilité">
              <IconButton
                onClick={() => {
                  socket().emit(
                    "update-item",
                    { ...item, isAvailable: !item.isAvailable },
                    updatedItem => {
                      onSave({
                        ...item,
                        ...updatedItem[0]
                      });
                    }
                  );
                }}
              >
                {item.isAvailable ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Modifier" aria-label="Modifier">
              <IconButton onClick={() => setEdit(true)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Effacer" aria-label="Effacer">
              <IconButton edge="end" onClick={() => setDialog(true)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        </ListItem>
        {open ? (
          <ConfirmDialog
            open={open}
            handleClose={() => setDialog(false)}
            callback={() => {
              socket().emit("delete-item", item, () => {
                Delete(item);
              });
            }}
            message={
              "Voulez-vous effacer de façon permanente l'élément du menu ?"
            }
            title={"Attention"}
          />
        ) : null}
      </>
    );
  }
};

export default MenuItem;
