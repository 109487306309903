import { toast } from "react-toastify";
import Currency from "currency.js";

export default (t, h = "H", fixedLength = false) => {
  const time = String(t);
  if (!time || !time.length) {
    return "";
  }

  let hour = "00";
  if (time.length > 2) {
    hour = time.slice(-4, -2);
  }

  let minutes = String(Number.parseInt(60 * (time.slice(-2) / 100)));

  if (minutes.length < 2) {
    minutes = "0" + minutes;
  }
  if (fixedLength) {
    if (hour.length < 2) {
      hour = "0" + hour;
    }
  }
  return hour + h + minutes;
};

export function round(x) {
  return Math.ceil(x / 25) * 25;
}

function isMultiple(x, y) {
  return x === 0 || !(x % y);
}

export const convertTimeNumber = t => {
  const time = Currency(t).divide(100);

  const getHour = () => {
    return time.dollars();
  };

  const getMinutes = () => {
    return time.cents();
  };

  let hour = getHour();
  let minutes = Number.parseInt(100 / (60 / getMinutes()));

  if (hour === 23 && getMinutes() === 59) {
    minutes = 99;
  } else if (!isMultiple(minutes, 25)) {
    minutes = round(minutes);
    toast.error("La valeur doit être un multiple de 15.");
  }

  return Currency(hour)
    .multiply(100)
    .add(Currency(minutes))
    .dollars();
};
