import React from "react";
import { useSelector } from "react-redux";

const PixelAction = ({ children, event, data }) => {
  const { facebookPixelID } = useSelector(state => state.settings);
  return (
    <div
      onClick={() => {
        if (facebookPixelID.value !== "") {
          window.fbq("track", event, data);
        }
      }}
    >
      {children}
    </div>
  );
};

export default PixelAction;
