import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Restaurants from "../../../utils/Restaurants";
import TextField from "@material-ui/core/TextField";
import Subheader from "../UI/Subheader";
import { useDispatch, useSelector } from "react-redux";

const FormDonation = () => {
  const reduxDispatch = useDispatch();

  const donation = useSelector(state => state.purchase.options.donation);
  const title = Restaurants().promotionNow
    ? Restaurants().promotionNow()
    : null;

  const d = donation !== undefined ? donation.amount : "";
  const anonyme = donation !== undefined ? donation.anonyme : false;

  if (!title) {
    return null;
  }

  const setDonation = e => {
    const value = isNaN(e) ? e.target.value : 0;
    if (value >= 0) {
      reduxDispatch({
        type: "[PURCHASE] set options",
        data: {
          donation: {
            amount: Number.parseFloat(value),
            anonyme: anonyme === "true",
            title
          }
        }
      });
    } else {
      reduxDispatch({
        type: "[PURCHASE] set options",
        data: {
          donation: undefined
        }
      });
    }
  };

  const setAnonyme = ({ target: { value: a } }) => {
    reduxDispatch({
      type: "[PURCHASE] set options",
      data: {
        donation: { amount: d, anonyme: a === "true", title }
      }
    });
  };

  return (
    <>
      <Subheader>{title}</Subheader>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center"
        }}
      >
        <FormControl
          component="fieldset"
          style={{ marginTop: 30, marginBottom: 10, maxWidth: 100 }}
        >
          <TextField
            id="montant"
            variant="outlined"
            value={d}
            type="number"
            label="Montant"
            min={5}
            onChange={setDonation}
          />
        </FormControl>
        <FormControl
          component="fieldset"
          style={{
            marginTop: 30,
            marginBottom: 10
          }}
        >
          <RadioGroup
            row
            aria-label="Don"
            name="Don"
            onChange={setAnonyme}
            value={String(anonyme)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {["Oui", "Non"].map(t => (
              <FormControlLabel
                key={t}
                value={String(t === "Oui")}
                control={<Radio color="primary" />}
                label={
                  t === "Oui"
                    ? "Je préfère rester anonyme"
                    : "Remerciez-les en mon nom!"
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

export default FormDonation;
