import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const ScrollTop = () => {
  const currentView = useSelector(state => state.purchase.view);

  const scrollTop = () => {
    window.scrollTo(
      Number.parseInt((document.body.clientWidth - window.innerWidth) / 2),
      0
    );
  };

  useEffect(() => {
    scrollTop();
  }, [currentView]);

  return <></>;
};

export default ScrollTop;
